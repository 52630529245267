import { createAction, createAsyncAction } from "typesafe-actions";

import { Offerb2bData, PlaceInterface } from "containers/Pages/Offerb2b/types";

import * as offerConstants from "./constants";
import { CurrentStepInterface, Offerb2bDadataInterface, PlaceCategoriesInterface } from "./types";

export const setCurrentStep = createAction(offerConstants.SET_CURRENT_STEP)<CurrentStepInterface>();
export const setLoading = createAction(offerConstants.SET_LOADING)<boolean>();
export const setPlaces = createAction(offerConstants.SET_PLACES)<PlaceInterface[]>();

export const fetchDadata = createAsyncAction(
  offerConstants.REQUEST_DADATA,
  offerConstants.SUCCESS_DADATA,
  offerConstants.FAILED_DADATA,
)<string, Offerb2bDadataInterface, void>();

export const fetchPlaceCategories = createAsyncAction(
  offerConstants.REQUEST_PLACE_CATEGORIES,
  offerConstants.SUCCESS_PLACE_CATEGORIES,
  offerConstants.FAILED_PLACE_CATEGORIES,
)<void, PlaceCategoriesInterface[], string>();

export const fetchPublicOffer = createAsyncAction(
  offerConstants.REQUEST_PUBLIC_OFFER,
  offerConstants.SUCCESS_PUBLIC_OFFER,
  offerConstants.FAILED_PUBLIC_OFFER,
)<Offerb2bData, void, void>();
