import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { setPlace } from "store/ui/actions";
import { getSelfSelector } from "store/self/selectors";
import { UserInterface } from "store/self/types";

import { getPlacesAsync } from "./actions";
import { MerchantPlacesResponse } from "./types";

export function* merchantPlacesRequest() {
  yield takeEvery(getPlacesAsync.request, function* (action) {
    try {
      const limit = 20;
      const answer: MerchantPlacesResponse = yield loaderManager.get(apiUrls.getMerchantPlacesSummary(), {
        offset: (action.payload.page - 1) * limit,
        limit,
        o: "city",
      });
      if (answer.success) {
        yield put(
          getPlacesAsync.success({
            merchantPlaces: answer.data,
            hasNextPage: !!answer.meta?.next,
            page: action.payload.page,
          }),
        );
      } else {
        yield put(getPlacesAsync.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getPlacesAsync.failure(e as string));
    }
  });
}

export function* merchantPlacesSave() {
  yield takeEvery(getPlacesAsync.success, function* ({ payload }) {
    if (payload.page !== 1 || payload.merchantPlaces.length === 0) return;
    const storedPlace = JSON.parse(localStorage.getItem("place")!);
    if (storedPlace) return;
    const self: UserInterface = yield select(getSelfSelector as any);
    const newPlace = self.places.find((place) => place.id === payload.merchantPlaces[0].id);
    if (!newPlace) return;
    yield put(setPlace({ label: newPlace.address, value: newPlace.id }));
  });
}

export function* merchantPlacesFailed() {
  yield takeEvery(getPlacesAsync.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* merchantPlacesSaga() {
  yield all([fork(merchantPlacesRequest), fork(merchantPlacesFailed), fork(merchantPlacesSave)]);
}
