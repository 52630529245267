import React from "react";
import { subscribe } from "store/firebase/actions";
import { useDispatch } from "react-redux";

export function InitFirebase({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(subscribe());
  }, [dispatch]);

  return children as JSX.Element;
}
