import React from "react";
import { IndicatorProps } from "react-select";
import styled from "styled-components";
import Icon from "primitives/Icon";

interface DropdownIndicatorInterface extends IndicatorProps<any, any> {
  innerRef?: any;
}

const Wrapper = styled.div`
  display: flex;
  padding: 0 16px;
  cursor: pointer;
`;

function DropdownIndicator({ innerProps, innerRef }: DropdownIndicatorInterface) {
  return (
    <Wrapper ref={innerRef} {...innerProps}>
      <Icon icon="arrows" />
    </Wrapper>
  );
}

export default React.memo(DropdownIndicator);
