import React, { ReactNode, Ref } from "react";
import styled, { CSSProperties } from "styled-components";
import { ThemeColors } from "styles/theme";

import { fontSize, fontWeight, lineHeight, textDots } from "./internal/style-helpers";

export const typographyTypes = {
  "title-card": [fontSize("2.125rem"), lineHeight("1.25rem"), fontWeight("bold")],
  title: [fontSize("2.5rem"), lineHeight("2.9375rem"), fontWeight("bold")],
  "h1-medium": [fontSize("2rem"), lineHeight("2.375rem"), fontWeight(500)],
  "h1-regular": [fontSize("2rem"), lineHeight("2.375rem"), fontWeight("normal")],
  "h2-bold": [fontSize("1.5rem"), lineHeight("1.75rem"), fontWeight("bold")],
  "h2-medium": [fontSize("1.5rem"), lineHeight("1.75rem"), fontWeight(500)],
  "h2-regular": [fontSize("1.5rem"), lineHeight("1.75rem"), fontWeight("normal")],
  "h3-bold": [fontSize("1.25rem"), lineHeight("1.5rem"), fontWeight("bold")],
  "h3-medium": [fontSize("1.25rem"), lineHeight("1.5rem"), fontWeight(500)],
  "h4-medium": [fontSize("1.125rem"), lineHeight("1.3125rem"), fontWeight(500)],
  "h4-regular": [fontSize("1.125rem"), lineHeight("1.3125rem"), fontWeight("normal")],
  "body-regular": [fontSize("1rem"), lineHeight("1.25rem"), fontWeight("normal")],
  "body-medium": [fontSize("1rem"), lineHeight("1.25rem"), fontWeight(500)],
  "info-regular": [fontSize("0.875rem"), lineHeight("1rem"), fontWeight("normal")],
  "info-medium": [fontSize("0.875rem"), lineHeight("1rem"), fontWeight(500)],
  "caption-regular": [fontSize("0.75rem"), lineHeight("0.875rem"), fontWeight("normal")],
  "caption-medium": [fontSize("0.75rem"), lineHeight("0.875rem"), fontWeight(500)],
};

export type TypographyTypes = keyof typeof typographyTypes;

const TypographyWrapper = styled.span<
  Required<Pick<TypographyInterface, "type" | "align" | "wordBreak" | "fullWidth">> &
    Pick<TypographyInterface, "color" | "dots" | "noWrap" | "inline">
>`
  display: ${({ inline }) => (inline ? "inline" : "inline-block")};
  ${({ type }) => typographyTypes[type]};
  color: ${({ color, theme }) => (color ? theme.colors[color] : theme.colors.black)};
  text-align: ${({ align }) => align};
  word-break: ${({ wordBreak }) => wordBreak};
  ${({ dots }) => dots && textDots};
  ${({ noWrap }) => noWrap && `white-space: nowrap`};
  width: ${({ fullWidth }) => fullWidth && "100%"};
`;

export interface TypographyInterface {
  tabIndex?: number;
  noWrap?: boolean;
  className?: string;
  as?: StyledComponentsAs<Omit<TypographyInterface, "as">>;
  type?: TypographyTypes;
  align?: CSSProperties["textAlign"];
  wordBreak?: CSSProperties["wordBreak"];
  textTransform?: CSSProperties["textTransform"];
  color?: ThemeColors;
  dots?: boolean;
  children: ReactNode;
  fullWidth?: boolean;
  asHTML?: boolean;
  inline?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
}

function Typography(
  {
    noWrap,
    as,
    className,
    children,
    type = "body-regular",
    align = "left",
    wordBreak = "normal",
    color = "black",
    inline,
    dots,
    asHTML,
    onClick,
    ...props
  }: TypographyInterface,
  ref: Ref<HTMLSpanElement>,
) {
  const contentProps = asHTML ? { dangerouslySetInnerHTML: { __html: children as string } } : { children };

  return (
    <TypographyWrapper
      ref={ref}
      as={as as any}
      className={className}
      align={align}
      wordBreak={wordBreak}
      noWrap={noWrap}
      type={type}
      color={color}
      dots={dots}
      inline={inline}
      onClick={onClick}
      {...props}
      {...contentProps}
    />
  );
}

export default React.memo(React.forwardRef(Typography));
