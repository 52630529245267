import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getDocuments, resetState } from "store/documents/actions";
import { DocumentsResponse } from "store/documents/types";

export function* documentsRequest() {
  yield takeEvery(getDocuments.request, function* ({ payload }: any) {
    try {
      const answer: DocumentsResponse = yield loaderManager.get(apiUrls.getMerchantDocumentList(), payload);

      if (answer.success) {
        yield put(resetState());
        yield put(getDocuments.success({ data: answer.data }));
      } else yield put(getDocuments.failure(answer.error.message));
    } catch (e) {
      yield put(getDocuments.failure(e as string));
    }
  });
}

export function* documentsFailed() {
  yield takeEvery(getDocuments.failure, function* (action: any) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* documentsSaga() {
  yield all([fork(documentsRequest), fork(documentsFailed)]);
}
