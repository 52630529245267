import { InitialState } from "types";

import { StatisticsResponse } from "./types";

export function mapper(data: StatisticsResponse) {
  const [completedOrdersEntity, canceledOrdersEntity] = data.completed_orders?.data ?? [null, null];
  const [ownPickUpTimeEntity, districtPickUpTimeEntity] = data.pick_up_time?.data ?? [null, null];

  const [ownDeliveryTimeEntity, districtDeliveryTimeEntity] = data.delivery_time?.data ?? [null, null];

  const [ownAvgRatingEntity, districtAvgRatingEntity] = data.average_rating?.data ?? [null, null];

  const [ownAvgPriceEntity, districtAvgPriceEntity] = data.average_price?.data ?? [null, null];

  return {
    ...data,
    completed_orders: data.completed_orders && {
      ...data.completed_orders,
      legend: {
        completed: completedOrdersEntity!.label,
        canceled: canceledOrdersEntity!.label,
      },
      valuesByCount: completedOrdersEntity!.values.map(({ count }, idx) => {
        const canceled = canceledOrdersEntity!.values[idx].count;

        return {
          completedValue: count.value,
          canceledValue: canceled.value,
          completedLabel: count.label,
          canceledLabel: canceled.label,
          tick: count.label_x,
        };
      }),
      valuesByPrice: completedOrdersEntity!.values.map(({ price }, idx) => {
        const canceled = canceledOrdersEntity!.values[idx].price;

        return {
          completedValue: price.value,
          canceledValue: canceled.value,
          completedLabel: price.label,
          canceledLabel: canceled.label,
          tick: price.label_x,
        };
      }),
    },
    pick_up_time: data.pick_up_time && {
      legend: {
        own: ownPickUpTimeEntity!.label,
        district: districtPickUpTimeEntity!.label,
      },
      values: ownPickUpTimeEntity!.values.map(({ time }, idx) => {
        const district = districtPickUpTimeEntity!.values[idx].time;

        return {
          ownValue: time.value,
          districtValue: district.value,
          ownLabel: time.label,
          districtLabel: district.label,
          tick: time.label_x,
        };
      }),
    },
    delivery_time: data.delivery_time && {
      legend: {
        own: ownDeliveryTimeEntity!.label,
        district: districtDeliveryTimeEntity!.label,
      },
      values: ownDeliveryTimeEntity!.values.map(({ time }, idx) => {
        const district = districtDeliveryTimeEntity!.values[idx].time;

        return {
          ownValue: time.value,
          districtValue: district.value,
          ownLabel: time.label,
          districtLabel: district.label,
          tick: time.label_x,
        };
      }),
    },
    average_rating: data.average_rating && {
      legend: {
        own: ownAvgRatingEntity!.label,
        district: districtAvgRatingEntity!.label,
      },
      values: ownAvgRatingEntity!.values.map(({ rating }, idx) => {
        const district = districtAvgRatingEntity!.values[idx].rating;

        return {
          ownValue: rating.value,
          districtValue: district.value,
          ownLabel: rating.label,
          districtLabel: district.label,
          tick: rating.label_x,
        };
      }),
    },
    average_price: data.average_price && {
      currency: data.average_price.currency,
      legend: {
        own: ownAvgPriceEntity!.label,
        district: districtAvgPriceEntity!.label,
      },
      values: ownAvgPriceEntity!.values.map(({ price }, idx) => {
        const district = districtAvgPriceEntity!.values[idx].price;

        return {
          ownValue: price.value,
          districtValue: district.value,
          ownLabel: price.label,
          districtLabel: district.label,
          tick: price.label_x,
        };
      }),
    },
  };
}

export type StatisticsType = ReturnType<typeof mapper>;

export type State = InitialState<StatisticsType>;
