import { AvailableMapProviders } from "@bb/components";

export const COOKIE_AUTH_NAME = "Merchants_JWT";
export const COOKIE_MOBILE_AUTH_TOKEN_NAME = "Origin-Auth-Token";
export const COOKIE_MOBILE_APP_TOKEN_NAME = "Origin-App-Token";
export const COOKIE_MOBILE_UA_NAME = "Origin-User-Agent";

export const PLATFORM = "web";
export const RESET_TOKEN_NAME_PARAM = "token";
export const INVITE_TOKEN_NAME_PARAM = "token";

export const REFRESH_HEADER_NAME = "X-Refresh-Token";
export const AUTH_HEADER_NAME = "X-Auth-Token";
export const WHITE_LIST_HEADER_NAME = "X-Whitelist-Header";
export const REAL_IP_HEADER_NAME = "X-Real-IP";
export const DDG_CONNECTING_IP_HEADER_NAME = "ddg-connecting-ip";
export const APP_TOKEN_HEADER_NAME = "App-Token";
export const APP_USER_AGENT_HEADER_NAME = "User-Agent";
export const APP_TOKEN_HEADER_VALUE = process.env.NEXT_PUBLIC_APP_TOKEN!;

export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_TOKEN!;
export const YANDEX_API_KEY = process.env.NEXT_PUBLIC_YANDEX_API_KEY!;
export const GEOCODER_PROVIDER = process.env.NEXT_PUBLIC_GEOCODER_PROVIDER! as AvailableMapProviders;

export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!;

export const FIREBASE_CONFIG = process.env.NEXT_PUBLIC_FIREBASE_CONFIG!;

export const HMAC_USERNAME = process.env.NEXT_PUBLIC_HMAC_USERNAME!;
export const HMAC_SECRET = process.env.NEXT_PUBLIC_HMAC_SECRET!;
