import { createAsyncAction, createAction } from "typesafe-actions";

import * as ordersConstants from "./constants";
import { AllAvailableActiveOrderWidgetsInterface } from "./types";

export const getActiveOrdersWidgets = createAsyncAction(
  ordersConstants.REQUEST,
  ordersConstants.SUCCESS,
  ordersConstants.FAILED,
)<
  { placeId: string; loading?: boolean },
  { data: AllAvailableActiveOrderWidgetsInterface[]; metaData: { serverTime: number } },
  string
>();

export const resetActiveOrdersWidgets = createAction(ordersConstants.RESET_ORDERS_WIDGETS)();

export const removeInfoMessage = createAction(ordersConstants.REMOVE_INFO_MESSAGE)<string>();
