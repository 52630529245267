import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getEmployees, initEmployees } from "store/employees/actions";
import { EmployeesResponse } from "store/employees/types";

export function* employeesRequest() {
  yield takeEvery(getEmployees.request, function* (action) {
    try {
      const answer: EmployeesResponse = yield loaderManager.get(apiUrls.merchantEmployees);
      if (answer.success) {
        yield put(getEmployees.success(answer.data));
      } else {
        yield put(getEmployees.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getEmployees.failure(e as string));
    }
  });
}

export function* employeesFailed() {
  yield takeEvery(getEmployees.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* employeesSaga() {
  yield all([fork(employeesRequest), fork(employeesFailed)]);
}
