import { RootState } from "store";
import { createSelector } from "reselect";
import { namespace } from "./constants";

export const selfState = (state: RootState) => state[namespace];

export const wasLoaded = createSelector(selfState, (state) => state.wasLoaded);
export const isLoading = createSelector(selfState, (state) => state.isLoading);
export const isError = createSelector(selfState, (state) => state.isError);
export const place = createSelector(selfState, (state) => state.place);
export const tokenData = createSelector(selfState, (state) => state.tokenData);
export const placeCuisines = createSelector(selfState, (state) => state.placeCuisines);
