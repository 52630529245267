import { all } from "@redux-saga/core/effects";
import authSaga from "./auth/saga";
import userSaga from "./self/saga";
import merchantContextSaga from "./merchantContext/saga";
import legalSaga from "./legal/saga";
import merchantPlacesSaga from "./places/saga";
import merchantB2BSaga from "./merchantB2B/saga";
import employeesSaga from "./employees/saga";
import documentsSaga from "./documents/saga";
import ordersSaga from "./orders/saga";
import orderDetailsSaga from "./orderDetails/saga";
import orderCourierTrackSaga from "./orderCourierTrack/saga";
import stopListSaga from "./stop-list/saga";
import activeOrdersWidgetsSaga from "./active-orders-widgets/saga";
import scheduleSaga from "./schedule/saga";
import uiSaga from "./ui/saga";
import firebaseSaga from "./firebase/saga";
import statisticsSaga from "./statistics/saga";
import offerSaga from "./offerb2c/saga";
import offerb2bSaga from "./offerb2b/saga";
import subscriptionSaga from "./subscription/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    legalSaga(),
    merchantContextSaga(),
    merchantPlacesSaga(),
    merchantB2BSaga(),
    employeesSaga(),
    documentsSaga(),
    ordersSaga(),
    orderDetailsSaga(),
    orderCourierTrackSaga(),
    stopListSaga(),
    activeOrdersWidgetsSaga(),
    scheduleSaga(),
    uiSaga(),
    firebaseSaga(),
    statisticsSaga(),
    offerSaga(),
    offerb2bSaga(),
    subscriptionSaga(),
  ]);
}

export { rootSaga };
