import React from "react";
import { setModalRootElement } from "primitives/Modal";
import { useInitRefreshCallbacks } from "containers/InitContainer/useInitRefreshCallbacks";

function InitContainer({ children }: { children: React.ReactNode }) {
  React.useEffect(() => {
    setModalRootElement(document.getElementById("__next")!);
  }, []);

  useInitRefreshCallbacks();
  return <>{children}</>;
}

export default React.memo(InitContainer);
