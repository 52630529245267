import { Action, combineReducers } from "redux";
import { addonsReducer } from "./addons/reducer";
import { servicesReducer } from "./services/reducer";
import { categoriesReducer } from "./categories/reducer";

const innerReducer = combineReducers({
  addons: addonsReducer,
  services: servicesReducer,
  categories: categoriesReducer,
});

export const stopListReducer = (state: any, action: Action) => {
  if (action.type === "@stop-list/RESET_STOPLIST") {
    state = undefined;
  }
  return innerReducer(state, action);
};
