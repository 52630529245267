import { createReducer } from "typesafe-actions";
import { initAuthTokens, resetAuth, loginAsync } from "./actions";
import { AuthState } from "store/auth/types";

const initialState: AuthState = {
  token: null,
  refresh_token: null,
  token_expire: null,
  isLoading: false,
  error: null,
};

const reducer = createReducer(initialState)
  .handleAction(loginAsync.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(initAuthTokens, (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
    error: null,
  }))
  .handleAction(loginAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(resetAuth, () => ({
    ...initialState,
  }));

export { reducer as authReducer };
