import styled from "styled-components";
import React, { ButtonHTMLAttributes } from "react";
import memoize from "lodash/memoize";
import Spinner from "primitives/Spinner";
import Typography, { typographyTypes } from "primitives/Typography";
import { useTranslation } from "hooks/i18n";
import { ThemeColors } from "styles/theme";

export type ButtonVariants = "primary" | "submit" | "warning" | "cancel" | "white" | "outline";
type VariantColors = {
  fontColor: "black" | "white" | "primary";
  backgroundColor: ThemeColors;
  primaryBorder?: boolean;
};

const getColorsByVariant = memoize((variant: ButtonVariants) => {
  const theme: VariantColors = { backgroundColor: "primary", fontColor: "white", primaryBorder: false };
  switch (variant) {
    case "submit": {
      theme.backgroundColor = "success";
      break;
    }
    case "warning": {
      theme.backgroundColor = "warning";
      break;
    }
    case "cancel": {
      theme.backgroundColor = "secondary_btn";
      theme.fontColor = "black";
      break;
    }
    case "white": {
      theme.backgroundColor = "white";
      theme.fontColor = "black";
      break;
    }
    case "outline": {
      theme.backgroundColor = "white";
      theme.fontColor = "primary";
      theme.primaryBorder = true;
      break;
    }
    default:
      break;
  }
  return theme;
});

export interface ButtonInterface extends ButtonHTMLAttributes<any> {
  variant?: ButtonVariants;
  loading?: boolean;
}

const ButtonWrapper = styled.button<ButtonInterface & VariantColors>`
  width: 100%;
  height: 48px;
  padding: 8px 0;
  border: ${({ theme, primaryBorder }) => (primaryBorder ? `1px ${theme.colors.primary} solid` : "none")};
  border-radius: ${({ theme }) => theme.ui.radius_s};
  color: ${({ theme, fontColor }) => theme.colors[fontColor]};
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  ${typographyTypes["body-medium"]}
  cursor: pointer;
  text-align: center;
  appearance: none;
  outline: 0;
  transition: opacity 0.2s;

  :hover {
    opacity: 0.8;
  }

  :disabled {
    opacity: 0.3;
    cursor: no-drop;
  }

  :active {
    opacity: 0.6;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    margin-right: 16px;
  }
`;

function Button({ children, loading, disabled, variant = "primary", ...rest }: ButtonInterface) {
  const { t } = useTranslation("common");
  const { fontColor, backgroundColor, primaryBorder } = getColorsByVariant(variant);
  return (
    <ButtonWrapper
      fontColor={fontColor}
      primaryBorder={primaryBorder}
      backgroundColor={loading ? "secondary" : backgroundColor}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <LoadingWrapper>
          <Spinner size="32px" color="white" />
          <Typography type="body-medium" color={fontColor}>
            {t("buttons.loading")}
          </Typography>
        </LoadingWrapper>
      ) : (
        children
      )}
    </ButtonWrapper>
  );
}

export default React.memo(Button);
