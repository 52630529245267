import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { sha256 } from "js-sha256";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { useMerchantContext } from "store/merchantContext/hooks";
import { loginAsync, logout } from "store/auth/actions";
import { INVITE_TOKEN_NAME_PARAM, PLATFORM, RESET_TOKEN_NAME_PARAM } from "constants/common";
import { isError, isLoading } from "store/auth/selectors";
import { ResponseFailType, ResponseSuccessType } from "types";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { handlerServerErrors } from "helpers/parse";
import { useLogEvent } from "hooks/analytics";
import { globalEventBus } from "helpers/globalEventBus";

import { useFingerprint } from "./useFingerprint";

export const initialLoginValues = {
  email: "",
  password: "",
};

export function useLogin() {
  const dispatch = useDispatch();
  const deviceId = useFingerprint();
  const isSubmitting = useSelector(isLoading);
  const isErrorSubmit = useSelector(isError);

  const merchantData = useMerchantContext();

  const login = React.useCallback(
    async ({ email, password }: typeof initialLoginValues) => {
      dispatch(
        loginAsync.request({
          email,
          password: sha256.create().update(password).hex(),
          platform: PLATFORM,
          device_id: deviceId,
        }),
      );
    },
    [deviceId, dispatch],
  );

  return { login, isSubmitting, isErrorSubmit, merchantData };
}

export const initialRestoreValues = {
  email: "",
};

export function useRestore(successCallback?: Function) {
  const deviceId = useFingerprint();
  const [logEvent] = useLogEvent();

  return React.useCallback(
    async ({ email }: typeof initialRestoreValues) => {
      try {
        const data: ResponseSuccessType<any> & ResponseFailType = await loaderManager.post(
          apiUrls.authMerchantResetPassUrl,
          {
            email: email,
            platform: PLATFORM,
            device_id: deviceId,
          },
        );

        if (data.success) {
          if (successCallback) successCallback();
          globalEventBus.emit("NOTIFICATION_TRANSLATION_SUCCESS", {
            message: "auth:reset.success.send_email_notification",
          });
        } else {
          logEvent("ResetPassword_Failed_To_Reset", {
            error: data.error.message,
            errorCode: data.error.code,
          });
          globalEventBus.emit("NOTIFICATION_ERROR", { message: data.error.message });
        }
      } catch (e) {
        globalEventBus.emit("NOTIFICATION_ERROR", { message: e as string });
      }
    },
    [deviceId, logEvent, successCallback],
  );
}

export const initialResetValues = {
  password: "",
  passwordConfirm: "",
};

export function useReset(successCallback?: Function) {
  const router = useRouter();
  const deviceId = useFingerprint();
  const [logEvent] = useLogEvent();

  return React.useCallback(
    async ({ password }: typeof initialResetValues) => {
      try {
        const resetToken = router.query[RESET_TOKEN_NAME_PARAM];
        const data: ResponseSuccessType<any> & ResponseFailType = await loaderManager.post(
          apiUrls.authMerchantSetPassUrl,
          {
            new_password: sha256.create().update(password).hex(),
            platform: PLATFORM,
            device_id: deviceId,
            reset_token: resetToken,
          },
        );

        if (data.success) {
          if (successCallback) successCallback();
          globalEventBus.emit("NOTIFICATION_TRANSLATION_SUCCESS", {
            message: "auth:reset.success_notification",
          });
        } else {
          logEvent("SetPassword_Failed_To_Set", {
            error: data.error.message,
            code: data.error.code,
          });
          handlerServerErrors(data.error.message);
        }
      } catch (e) {
        globalEventBus.emit("NOTIFICATION_ERROR", { message: (e as Error).message });
      }
    },
    [deviceId, logEvent, router.query, successCallback],
  );
}

export const initialRegistrationValues = {
  name: "",
  surname: "",
  password: "",
  passwordConfirm: "",
};

export function useRegistration(successCallback?: Function) {
  const router = useRouter();
  const deviceId = useFingerprint();
  const [loading, setLoading] = useState(true);
  const [credentials, setCredentials] = useState<{ name: string; surname: string }>();

  const [logEvent] = useLogEvent();

  const initToken = React.useCallback(async () => {
    try {
      const inviteToken = router.query[INVITE_TOKEN_NAME_PARAM];
      const data: ResponseSuccessType<{ name: string; surname: string }> & ResponseFailType = await loaderManager.get(
        apiUrls.checkInviteUrl,
        {
          invite_token: inviteToken,
        },
      );

      if (data.success) {
        setCredentials({
          name: data.data.name,
          surname: data.data.surname,
        });
        setLoading(false);
      } else {
        handlerServerErrors(data.error.message);
      }
    } catch (e) {
      globalEventBus.emit("NOTIFICATION_ERROR", { message: (e as Error).message });
    }
  }, [router.query]);

  useEffectOnce(() => void initToken());

  const registration = React.useCallback(
    async ({ name, surname, password }: typeof initialRegistrationValues) => {
      try {
        const inviteToken = router.query[INVITE_TOKEN_NAME_PARAM];
        const data: ResponseSuccessType<any> & ResponseFailType = await loaderManager.post(apiUrls.acceptInviteUrl, {
          platform: PLATFORM,
          device_id: deviceId,
          invite_token: inviteToken,
          name,
          surname,
          password: sha256.create().update(password).hex(),
        });

        if (data.success) {
          if (successCallback) successCallback();
          globalEventBus.emit("NOTIFICATION_TRANSLATION_SUCCESS", {
            message: "auth:invite.success_notification",
          });
        } else {
          logEvent("CreateAccount_Failed_To_Create", {
            error: data.error.message,
            code: data.error.code,
          });
          handlerServerErrors(data.error.message);
        }
      } catch (e) {
        globalEventBus.emit("NOTIFICATION_ERROR", { message: (e as Error).message });
      }
    },
    [deviceId, logEvent, router.query, successCallback],
  );

  return {
    registration,
    credentials,
    loading,
  };
}

export function useLogout() {
  const [logEvent] = useLogEvent();
  const dispatch = useDispatch();

  return React.useCallback(() => {
    logEvent("Profile_Log_Out");
    window.amplitude.getInstance().setUserId(null);
    dispatch(logout());
  }, [dispatch, logEvent]);
}
