import React from "react";
import styled from "styled-components";
import NetworkState from "primitives/NetworkState/NetworkState";
import { useSelfLoader } from "store/self/hooks";

const FullWrapper = styled.div`
  position: relative;
  width: 100%;
`;

function SelfContainer({ children }: { children: React.ReactNode }) {
  const { isLoading, wasLoaded, errorHandler, self } = useSelfLoader();
  return (
    <NetworkState isLoading={isLoading || !self} wasLoaded={wasLoaded} errorHandler={errorHandler}>
      <FullWrapper>{children}</FullWrapper>
    </NetworkState>
  );
}

export default React.memo(SelfContainer);
