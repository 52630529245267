import { createReducer } from "typesafe-actions";
import { StopListServicesState } from "./types";
import { getServices, resetServices, changeServiceType } from "./actions";

const initialState: StopListServicesState = {
  isLoading: false,
  wasLoaded: false,
  isError: false,
  data: [],
  meta: {
    count: 0,
    next: "",
    previous: "",
  },
};

const reducer = createReducer(initialState)
  .handleAction(getServices.request, (state) => ({
    ...state,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getServices.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: [...state.data, ...action.payload.data],
    meta: {
      ...state.meta,
      ...action.payload.meta,
    },
  }))
  .handleAction(getServices.failure, (state) => ({
    ...state,
    isError: true,
  }))
  .handleAction(changeServiceType, (state, { payload }) => ({
    ...state,
    data: state.data.map((service) => {
      return service.id === payload.id
        ? {
            ...service,
            stop_list_type: payload.stop_list_type,
          }
        : service;
    }),
  }))
  .handleAction(resetServices, () => initialState);

export { reducer as servicesReducer };
