
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "core-js/es/global-this";
import "intl-pluralrules";

import React from "react";
import { wrapper } from "store";
import { AppProps } from "next/app";
import { InitAuthContainer } from "containers/InitAuthContainer";
import RootAppLayout from "components/RootAppLayout";
import InitContainer from "containers/InitContainer";
import SelfContainer from "containers/SelfContainer";
import RootContainer from "containers/RootContainer";
import "react-toastify/dist/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-circular-progressbar/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "@bb/components/dist/main-bundle.esm.css";
import { MOBILE_TOKEN_EXPIRE_PAGE } from "constants/urls";

function CustomApp({ Component, pageProps, router }: AppProps) {
  if (router.pathname.startsWith(MOBILE_TOKEN_EXPIRE_PAGE)) return <Component {...pageProps} />;

  if (router.pathname.startsWith("/offer") && !router.pathname.startsWith("/offerb2b"))
    return <Component {...pageProps} />;

  if (router.pathname.startsWith("/subscription")) return <Component {...pageProps} />;

  if (router.pathname.startsWith("/payments")) return <Component {...pageProps} />;

  if (router.pathname.startsWith("/download")) return <Component {...pageProps} />;

  if (router.pathname.startsWith("/auth") || router.pathname.startsWith("/offerb2b"))
    return (
      <InitContainer>
        <Component {...pageProps} />
      </InitContainer>
    );

  return (
    <InitContainer>
      <InitAuthContainer>
        <SelfContainer>
          <RootAppLayout>
            <Component {...pageProps} />
          </RootAppLayout>
        </SelfContainer>
      </InitAuthContainer>
    </InitContainer>
  );
}

const __Page_Next_Translate__ = wrapper.withRedux(function App(props: AppProps) {
  return (
    <RootContainer>
      <CustomApp {...props} />
    </RootContainer>
  );
});


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  