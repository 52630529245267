import React from "react";
import axios from "axios";
import { ThemeProvider } from "styled-components";
import { ConnectedRouter } from "connected-next-router";
import { MapsConfigProvider } from "@bb/components";
import { theme } from "styles/theme";
import { GlobalStyles } from "styles";
import Amplitude from "containers/Amplitude";
import { InitFirebase } from "containers/firebase/InitFirebase";
import { useMerchantContextLoader } from "store/merchantContext/hooks";
import NetworkState from "primitives/NetworkState/NetworkState";
import { GEOCODER_PROVIDER, GOOGLE_API_KEY, YANDEX_API_KEY } from "constants/common";

import NotificationsContainer from "./NotificationsContainer";

interface RootContainerInterface {
  children: JSX.Element;
}

function RootContainer({ children }: RootContainerInterface) {
  React.useEffect(() => {
    window.onerror = function (error) {
      axios.post("/api/log-errors", error);
    };
  }, []);

  const [merchantContext, reloadMerchantContext] = useMerchantContextLoader();

  return (
    <ConnectedRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Amplitude>
          <InitFirebase>
            <MapsConfigProvider
              mapsProvider={GEOCODER_PROVIDER}
              geocoderProvider={GEOCODER_PROVIDER}
              mapsLanguage="ru"
              geocoderLanguage="ru"
              googleApiKey={GOOGLE_API_KEY}
              yandexApiKey={YANDEX_API_KEY}
            >
              <NetworkState
                isLoading={!merchantContext}
                wasLoaded={!!merchantContext}
                errorHandler={reloadMerchantContext}
              >
                {children}
              </NetworkState>
            </MapsConfigProvider>
            <NotificationsContainer />
          </InitFirebase>
        </Amplitude>
      </ThemeProvider>
    </ConnectedRouter>
  );
}

export default React.memo(RootContainer);
