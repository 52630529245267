import { AxiosRequestConfig } from "axios";
import { sha256 } from "js-sha256";
import { isEmpty } from "lodash";
import { APP_TOKEN_HEADER_VALUE, HMAC_SECRET, HMAC_USERNAME } from "../constants/common";

export function createRequestLine(config: AxiosRequestConfig) {
  const method = config.method!.toUpperCase();
  const httpVersion = "HTTP/1.1";
  return `${method} /${config.url} ${httpVersion}`;
}

export function createDigest({ data }: AxiosRequestConfig): string | null {
  if (isEmpty(data)) return null;

  const dataString = JSON.stringify(data);
  const encrypted = sha256(dataString);
  const encodedBody = Buffer.from(encrypted, "hex").toString("base64");

  return `SHA-256=${encodedBody}`;
}

export const createProxyAuthorization = (requestLine: string, digest: string | null, currentDate: string): string => {
  try {
    const headers = `app-token x-date${digest ? " digest" : ""} request-line`;
    const signingString = `app-token: ${APP_TOKEN_HEADER_VALUE}\nx-date: ${currentDate}\n${
      digest ? `digest: ${digest}\n` : ""
    }${requestLine}`;

    const signed = sha256.hmac.create(HMAC_SECRET).update(signingString).toString();

    const signBase64 = Buffer.from(signed, "hex").toString("base64");

    return `hmac username="${HMAC_USERNAME}", \
algorithm="hmac-sha256", \
headers="${headers}", \
signature="${signBase64}"`;
  } catch (e) {
    return "";
  }
};
