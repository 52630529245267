export const namespace = "@merchantB2B";

export const REQUEST = "@merchantB2B/REQUEST";
export const FAILED = "@merchantB2B/FAILED";
export const SUCCESS = "@merchantB2B/SUCCESS";

export const DELIVERY_ZONES_REQUEST = "@deliveryZones/REQUEST";
export const DELIVERY_ZONES_FAILED = "@deliveryZones/FAILED";
export const DELIVERY_ZONES_SUCCESS = "@deliveryZones/SUCCESS";

export const RESET_B2B = "@merchantB2B/RESET_B2B";
