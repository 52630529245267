import React from "react";
import styled from "styled-components";
import Spinner from "primitives/Spinner";
import Typography from "primitives/Typography";
import { useTranslation } from "hooks/i18n";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 80px;
`;

const Message = styled(Typography)`
  margin: 10px 0 0;
`;

interface LoadingInterface {
  enabled?: boolean;
}

function Loading({ enabled = true }: LoadingInterface) {
  const { t } = useTranslation("common");
  return (
    <Wrapper>
      {enabled && (
        <>
          <Spinner size="50px" />
          <Message align="center">{t("loading")}</Message>
        </>
      )}
    </Wrapper>
  );
}

export default React.memo(Loading);
