import React from "react";
import styled from "styled-components";

interface MenuInterface {
  withRightMargin?: boolean;
  children: React.ReactNode;
}

const StyledMenuList = styled.ul<{ withRightMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-bottom: solid ${({ theme }) => theme.colors.border} 1px;
  ${({ withRightMargin }) => withRightMargin && "margin-right: 20px"};
`;

function MenuList({ children, withRightMargin }: MenuInterface) {
  return <StyledMenuList withRightMargin={withRightMargin}>{children}</StyledMenuList>;
}

export default React.memo(MenuList);
