import { createReducer } from "typesafe-actions";
import { InitialState } from "types";

import { getActiveOrdersWidgets, removeInfoMessage, resetActiveOrdersWidgets } from "./actions";
import { AllAvailableActiveOrderWidgetsInterface } from "./types";

const initialState: InitialState<AllAvailableActiveOrderWidgetsInterface[]> & {
  isError: boolean;
  metaData: { serverTime: number };
} = {
  isLoading: false,
  wasLoaded: false,
  data: null!,
  metaData: null!,
  isError: false,
};

const reducer = createReducer(initialState)
  .handleAction(getActiveOrdersWidgets.request, (state, { payload: { loading = true } }) => ({
    ...state,
    wasLoaded: !loading,
    isLoading: loading,
    isError: false,
  }))
  .handleAction(getActiveOrdersWidgets.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: action.payload.data,
    metaData: action.payload.metaData,
  }))
  .handleAction(getActiveOrdersWidgets.failure, (state) => ({
    ...state,
    isError: true,
  }))
  .handleAction(resetActiveOrdersWidgets, () => initialState)
  .handleAction(removeInfoMessage, (state, action) => ({
    ...state,
    data: state.data.map((widget) => {
      if (widget.widget !== "info-message") return widget;
      return { ...widget, content: widget.content.filter((message) => message?.message_id !== action.payload) };
    }),
  }));

export { reducer as activeOrdersWidgetsReducer };
