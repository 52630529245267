import React from "react";
import { loaderManager } from "helpers/loaderManager";
import { SuccessLoginPayloadType } from "store/auth/types";
import { refreshTokensFailed, refreshTokensSuccess } from "store/auth/actions";
import { useDispatch } from "react-redux";

function useInitRefreshCallbacks() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    loaderManager.setCallbacks(
      (userAuthData: SuccessLoginPayloadType) => dispatch(refreshTokensSuccess(userAuthData)),
      () => dispatch(refreshTokensFailed()),
    );
  }, [dispatch]);
}

export { useInitRefreshCallbacks };
