import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantContext } from "store/merchantContext/actions";
import { getContext } from "store/merchantContext/selectors";

export function useMerchantContext() {
  return useSelector(getContext);
}

export function useMerchantContextLoader() {
  const merchantContext = useMerchantContext();
  const dispatch = useDispatch();

  const loadMerchantContext = useCallback(() => dispatch(getMerchantContext.request()), [dispatch]);

  useEffect(() => {
    if (merchantContext) return;
    loadMerchantContext();
  }, [loadMerchantContext, merchantContext]);

  return [merchantContext, loadMerchantContext] as const;
}
