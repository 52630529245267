import { createAsyncAction, createAction } from "typesafe-actions";

import * as placesConstants from "./constants";
import { MerchantPlace } from "./types";

export const getPlacesAsync = createAsyncAction(
  placesConstants.REQUEST,
  placesConstants.SUCCESS,
  placesConstants.FAILED,
)<void, Array<MerchantPlace>, string>();

export const resetPlaces = createAction(placesConstants.RESET_PLACES)();
