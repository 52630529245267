import React from "react";
import Button, { ButtonVariants } from "primitives/Button";

interface ActionButtonInterface {
  text?: string;
  variant: ButtonVariants;
  loading?: boolean;
  disabled?: boolean;
  close: () => void;
  onClick?: (close: () => void) => void;
}

function ActionButton({ loading, text, variant, disabled, onClick, close }: ActionButtonInterface) {
  const handleClick = React.useCallback(() => onClick && onClick(close), [close, onClick]);

  if (!onClick || !text) return null;

  return (
    <Button variant={variant} loading={loading} disabled={disabled} onClick={handleClick}>
      {text}
    </Button>
  );
}

export default React.memo(ActionButton);
