import axios, { AxiosError, AxiosResponse } from "axios";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { ResponseFailType } from "types";
import { LoginResponseType } from "store/auth/types";
import { APP_TOKEN_HEADER_NAME, AUTH_HEADER_NAME, REFRESH_HEADER_NAME } from "constants/common";
import { MOBILE_TOKEN_EXPIRE_PAGE } from "constants/urls";
import { createDigest, createProxyAuthorization, createRequestLine } from "./hmac";

const NEED_REFRESH_TOKEN_CODE = 4;

let isAlreadyFetchingAccessToken = false;

let subscribers: ((token: string) => void)[] = [];

export const customAxios = axios.create({
  baseURL: "/api",
});

customAxios.interceptors.request.use(
  (req) => {
    try {
      const date = new Date().toUTCString();
      const requestLine = createRequestLine(req);
      const digest = createDigest(req);
      const proxyAuth = createProxyAuthorization(requestLine, digest, date);

      if (!req.headers) req.headers = {};
      req.headers["x-date"] = date;
      req.headers["Authorization"] = proxyAuth;
      if (digest) req.headers["Digest"] = digest;
    } catch (e) {
      return req;
    }

    return req;
  },
  (err) => Promise.reject(err),
);

customAxios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (isTokenExpiredError(error.response)) {
      if (loaderManager.isMobileApp) {
        window.location.pathname = MOBILE_TOKEN_EXPIRE_PAGE;
        return;
      }
      return resetTokenAndReattemptRequest(error);
    }

    return Promise.reject(error);
  },
);

const isTokenExpiredError = (error: AxiosResponse): boolean => {
  try {
    const data: ResponseFailType = error.data;
    return loaderManager.authData && data?.error?.code === NEED_REFRESH_TOKEN_CODE && error.status === 401;
  } catch (e) {
    return false;
  }
};

const resetTokenAndReattemptRequest = async (error: AxiosError) => {
  try {
    const { response: errorResponse } = error;

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((token) => {
        if (!errorResponse!.config.headers) errorResponse!.config.headers = {};
        errorResponse!.config.headers[AUTH_HEADER_NAME] = token;
        resolve(customAxios(errorResponse!.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response: LoginResponseType = await customAxios
        .get(apiUrls.refreshTokenUrl, {
          headers: {
            [APP_TOKEN_HEADER_NAME]: loaderManager.APP_TOKEN,
            [REFRESH_HEADER_NAME]: loaderManager.authData.refresh_token,
            [AUTH_HEADER_NAME]: loaderManager.authData?.token,
          },
        })
        .then((res) => res.data)
        .catch((err) => err.response.data);

      if (!response.success) {
        loaderManager.failRefreshAuthCb();
        return Promise.reject(error);
      }

      const authData = response.data;
      loaderManager.initAuthData(authData);
      loaderManager.successRefreshAuthCb(authData);
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(authData.token);
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
};

const onAccessTokenFetched = (token: string) => {
  subscribers.forEach((callback) => callback(token));
  subscribers = [];
};

const addSubscriber = (callback: (token: string) => void) => {
  subscribers.push(callback);
};
