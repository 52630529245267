import { createAsyncAction } from "typesafe-actions";

import * as orderDetailsConstants from "./constants";
import { OrderCourierTrackResponse } from "./types";

export const getOrderCourierTrack = createAsyncAction(
  orderDetailsConstants.COURIER_TRACK_REQUEST,
  orderDetailsConstants.COURIER_TRACK_SUCCESS,
  orderDetailsConstants.COURIER_TRACK_FAILED,
)<{ orderId: string; clearOrder?: boolean }, OrderCourierTrackResponse, string>();
