import { createAsyncAction, createAction } from "typesafe-actions";
import * as authConstants from "./constants";
import { LoginData, SuccessLoginPayloadType } from "store/auth/types";

export const loginAsync = createAsyncAction(authConstants.REQUEST, authConstants.SUCCESS, authConstants.FAILED)<
  LoginData,
  SuccessLoginPayloadType,
  { message: string; code: number }
>();

export const restoreTokens = createAction(authConstants.RESTORE_TOKENS)();

export const refreshTokensSuccess = createAction(authConstants.REFRESH_TOKENS_SUCCESS)<SuccessLoginPayloadType>();
export const refreshTokensFailed = createAction(authConstants.REFRESH_TOKENS_FAILED)();

export const initAuthTokens = createAction(authConstants.INIT_AUTH)<SuccessLoginPayloadType>();

export const logout = createAction(authConstants.LOGOUT)();
export const resetAuth = createAction(authConstants.RESET_AUTH)();
