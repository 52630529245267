import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .popup {
    opacity: 1;
    
    .rc-tooltip-arrow {
      display: none;
    }
  }
`;
