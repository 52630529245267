import { createSelector } from "reselect";
import { RootState } from "store";
import { namespace } from "store/self/constants";

const selfState = (state: RootState) => state[namespace];

export const getSelfSelector = createSelector(selfState, (state) => state.data);

export const selfWasLoaded = createSelector(selfState, (state) => state.wasLoaded);

export const selfIsLoading = createSelector(selfState, (state) => state.isLoading);
