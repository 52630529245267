import React from "react";
import styled from "styled-components";
import { useMeasure, useProvideRef } from "@worksolutions/react-utils";

import { config } from "configs";
import MenuContainer from "containers/Menu";
import { useUIState } from "store/ui/hooks";

import { useMenuSticky } from "./hooks";

interface AppLayoutInterface {
  children: React.ReactNode;
}

const Layout = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 135px 57px;
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.appBackground};
  @media ${({ theme }) => theme.breakpoints.appMobileBreakpoint} {
    padding: 0 35px 57px;
  }
  @media print {
    background-color: white;
    padding: 0 25px 33px 25px;
  }
`;

const LogoWrapper = styled.div`
  margin-left: auto;
  margin-bottom: 33px;
`;

const Logo = styled.img`
  max-width: 100%;
  height: auto;
`;

const MenuSection = styled.div`
  position: fixed;
  top: 0;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 33px 45px 30px 0;
  align-self: flex-start;
  flex-shrink: 0;
  border-right: solid ${({ theme }) => theme.colors.border} 1px;
  @media ${({ theme }) => theme.breakpoints.appMobileBreakpoint} {
    display: none;
  }
  @media print {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  margin-left: auto;
`;

const ContentSection = styled.div`
  padding-top: 33px;
  width: 100%;
  flex-shrink: 1;
`;

const SizesBalancer = styled.div<{ width: number }>`
  height: 912px;
  min-width: ${({ width }) => width}px;
  visibility: hidden;
  margin-right: 56px;
`;

function RootAppLayout({ children }: AppLayoutInterface) {
  const { isWebViewMode } = useUIState();
  const initMenuSticky = useMenuSticky();
  const [initMenuMeasure, menuClientRect] = useMeasure();
  const initMenuRef = useProvideRef(initMenuSticky, initMenuMeasure);

  return (
    <Layout>
      {!isWebViewMode && (
        <>
          <SizesBalancer width={menuClientRect.width} />
          <MenuSection ref={initMenuRef}>
            <MenuWrapper>
              <LogoWrapper>
                <Logo src={config.mainLogo} width="150" height="50" />
              </LogoWrapper>
              <MenuContainer />
            </MenuWrapper>
          </MenuSection>
        </>
      )}
      <ContentSection>{children}</ContentSection>
    </Layout>
  );
}

export default React.memo(RootAppLayout);
