import React from "react";
import { ButtonVariants } from "../Button";

export enum ModalSize {
  ADJUST_CONTENT = "ADJUST_CONTENT",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA = "EXTRA",
}

export interface ModalInterface {
  WrapperComponent?: React.FC<{ open: () => void }> | React.ReactNode;
  size?: ModalSize;
  opened?: boolean;
  primaryActionText?: string;
  secondaryActionText?: string;
  primaryActionVariant?: ButtonVariants;
  secondaryActionVariant?: ButtonVariants;
  primaryActionLoading?: boolean;
  secondaryActionLoading?: boolean;
  primaryActionDisabled?: boolean;
  secondaryActionDisabled?: boolean;
  title?: string;
  closeOnSecondaryActionClick?: boolean;
  closeOnBackdropClick?: boolean;
  excludePaddings?: boolean;
  forceTop?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onPrimaryActionClick?: (close: () => void) => void;
  onSecondaryActionClick?: (close: () => void) => void;
  children?: React.FC<{ close: () => void }> | React.ReactNode;
}
