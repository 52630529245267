import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import { push } from "connected-next-router";
import router from "next/router";

import { subscriptionFinishStep, subscriptionRequest } from "containers/Pages/Subscription/constants";
import { urls } from "constants/urls";

import { apiUrls } from "helpers/apiUrls";
import { loaderManager } from "helpers/loaderManager";
import { globalEventBus } from "helpers/globalEventBus";
import { OfferDadataInterface, savePlaceToSessionStorage } from "helpers/offer";
import {
  getSubscriptionRequestData,
  makeCuisineListRequest,
  makeSubscriptionOfferRequest,
  memoizedMakeDadataRequest,
} from "helpers/subscription";

import { NumberOption } from "types/forms";

import { placeCuisines } from "./selectors";
import { checkPlaceCoordinates, fetchPlaceCuisines, setPlace, fetchDadata, fetchSubscriptionOffer } from "./actions";

import { Response, SubscriptionResponse } from "./types";

export function* checkCoordinatesRequest() {
  yield takeEvery(checkPlaceCoordinates.request, function* ({ payload }) {
    try {
      const answer: Response = yield loaderManager.get(apiUrls.checkCoordinates(), {
        latitude: payload.latitude,
        longitude: payload.longitude,
      });

      if (answer.success) {
        yield put(checkPlaceCoordinates.success(payload));
        if (answer.data.delivery_available) {
          yield put(setPlace({ ...payload, valid: true }));
          yield put(push(urls.subscriptionRequest.url));
        } else {
          yield put(setPlace({ ...payload, valid: false }));
          yield put(push(urls.subscriptionCityRequest.url));
        }
      } else {
        yield put(checkPlaceCoordinates.failure(answer.error.message));
      }
    } catch (e) {
      yield put(checkPlaceCoordinates.failure(e as string));
    }
  });
}

export function* checkCoordinatesFailed() {
  yield takeEvery(checkPlaceCoordinates.failure, function* (action) {
    yield globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export function* fetchDadataRequest() {
  yield takeEvery(fetchDadata.request, function* ({ payload }) {
    const cuisineOptions: NumberOption[] = yield select(placeCuisines);

    if (cuisineOptions.length === 0) yield put(fetchPlaceCuisines.request());
    const response: OfferDadataInterface = yield memoizedMakeDadataRequest(payload);

    if (!response) return void (yield put(fetchDadata.failure()));
    yield put(fetchDadata.success(response));
  });
}

export function* fetchDadataFailed() {
  yield takeEvery(fetchDadata.failure, function* () {
    yield globalEventBus.emit("NOTIFICATION_TRANSLATION_ERROR", { message: "offer:common.wrong_inn" });
  });
}

export function* fetchPlaceCuisinesRequest() {
  yield takeEvery(fetchPlaceCuisines.request, function* () {
    const response: SubscriptionResponse<NumberOption[]> = yield makeCuisineListRequest();
    const cuisinesOptions = response?.data?.map((cuisines: any) => ({ label: cuisines.name, value: cuisines.id }));

    if (response?.error) return void (yield put(fetchPlaceCuisines.failure(response?.error?.message!)));
    yield put(fetchPlaceCuisines.success(cuisinesOptions!));
  });
}

export function* fetchPlaceCuisinesFailed() {
  yield takeEvery(fetchPlaceCuisines.failure, function* ({ payload }) {
    yield globalEventBus.emit("NOTIFICATION_ERROR", { message: payload });
  });
}

export function* fetchSubscriptionOfferRequest() {
  yield takeEvery(fetchSubscriptionOffer.request, function* ({ payload: { values, dadata } }) {
    const requestData = getSubscriptionRequestData(values, dadata);
    const response: SubscriptionResponse<any> = yield makeSubscriptionOfferRequest(requestData);

    if (response?.error) return void (yield put(fetchSubscriptionOffer.failure(response?.error?.message!)));

    sessionStorage.setItem(subscriptionFinishStep, subscriptionRequest);
    router.push(urls.subscriptionFinish.url);
  });
}

export function* fetchSubscriptionOfferFailed() {
  yield takeEvery(fetchSubscriptionOffer.failure, function* ({ payload }) {
    yield globalEventBus.emit("NOTIFICATION_ERROR", { message: payload });
  });
}

export function* savePlace() {
  yield takeEvery(setPlace, function* ({ payload }) {
    yield savePlaceToSessionStorage(payload);
  });
}

export default function* subscriptionSaga() {
  yield all([
    fork(checkCoordinatesRequest),
    fork(checkCoordinatesFailed),
    fork(fetchDadataRequest),
    fork(fetchDadataFailed),
    fork(fetchPlaceCuisinesRequest),
    fork(fetchPlaceCuisinesFailed),
    fork(fetchSubscriptionOfferRequest),
    fork(fetchSubscriptionOfferFailed),
    fork(savePlace),
  ]);
}
