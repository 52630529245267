import memoize from "lodash/memoize";
import { string1 } from "@worksolutions/utils";
import { css, CSSProperties } from "styled-components";

export const fontSize = memoize(
  (value: CSSProperties["fontSize"]) => css`
    font-size: ${value};
  `,
  string1,
);

export const lineHeight = memoize(
  (value: CSSProperties["lineHeight"]) => css`
    line-height: ${value};
  `,
  string1,
);

export const fontWeight = memoize(
  (value: CSSProperties["fontWeight"]) => css`
    font-weight: ${value};
  `,
  string1,
);

export const textDots = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;
