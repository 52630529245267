export const namespace = "@offerb2b";

export const SET_CURRENT_STEP = "@offerb2b/SET_CURRENT_STEP";
export const SET_LOADING = "@offerb2b/SET_LOADING";
export const SET_PLACES = "@offerb2b/SET_PLACES";

export const REQUEST_DADATA = "@offerb2b/DADATA_REQUEST";
export const FAILED_DADATA = "@offerb2b/DADATA_FAILED";
export const SUCCESS_DADATA = "@offerb2b/DADATA_SUCCESS";

export const REQUEST_PLACE_CATEGORIES = "@offerb2b/PLACE_CATEGORIES_REQUEST";
export const FAILED_PLACE_CATEGORIES = "@offerb2b/PLACE_CATEGORIES_FAILED";
export const SUCCESS_PLACE_CATEGORIES = "@offerb2b/PLACE_CATEGORIES_SUCCESS";

export const REQUEST_PUBLIC_OFFER = "@offerb2b/PUBLIC_OFFER_REQUEST";
export const FAILED_PUBLIC_OFFER = "@offerb2b/PUBLIC_OFFER_FAILED";
export const SUCCESS_PUBLIC_OFFER = "@offerb2b/PUBLIC_OFFER_SUCCESS";

export const REQUEST_DELIVERY_ZONES = "@offerb2b/DELIVERY_ZONES_REQUEST";
export const FAILED_DELIVERY_ZONES = "@offerb2b/DELIVERY_ZONES_FAILED";
export const SUCCESS_DELIVERY_ZONES = "@offerb2b/DELIVERY_ZONES_SUCCESS";
