import { createAsyncAction, createAction } from "typesafe-actions";

import * as placesConstants from "./constants";
import { MultiPlaceInterface } from "./types";

export const getPlacesAsync = createAsyncAction(
  placesConstants.REQUEST,
  placesConstants.SUCCESS,
  placesConstants.FAILED,
)<{ page: number }, { merchantPlaces: MultiPlaceInterface[]; hasNextPage: boolean; page: number }, string>();

export const resetPlaces = createAction(placesConstants.RESET)();
