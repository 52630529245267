import React from "react";
import nextUseTranslation from "next-translate/useTranslation";
import { useEffectSkipFirst } from "@worksolutions/react-utils";
import { isDeepEqual } from "@worksolutions/utils";
import { format } from "date-fns";
import ruLocale from "date-fns/locale/ru";
import uzLocale from "date-fns/locale/uz";
import { InternalContext } from "next-translate/I18nProvider";
import { usePrevious } from "react-use";

export type AvailableLanguages = keyof typeof matchLanguageAndDateFnsLocale;

export function useTranslation(defaultNs?: string) {
  const nextTranslateContext = React.useContext(InternalContext);
  const nextTranslation = nextUseTranslation(defaultNs);
  const [translationState, setTranslationState] = React.useState(nextTranslation);
  const previousNs = usePrevious(nextTranslateContext.ns);

  useEffectSkipFirst(() => {
    if (nextTranslateContext.ns === previousNs || isDeepEqual(nextTranslateContext.ns, previousNs)) return;
    setTranslationState(nextTranslation);
  }, [nextTranslateContext.ns, previousNs]);

  useEffectSkipFirst(() => {
    setTranslationState(nextTranslation);
  }, [nextTranslation.lang]);

  return { t: translationState.t, language: translationState.lang as AvailableLanguages };
}

const matchLanguageAndDateFnsLocale = {
  ru: ruLocale,
  uz: uzLocale,
};

export function getDateFNSLocaleByLanguage(language: AvailableLanguages) {
  return matchLanguageAndDateFnsLocale[language];
}

type FormatParameters = Parameters<typeof format>;

export function useFormatDate(incomeLang?: AvailableLanguages) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const lang = incomeLang || useTranslation().language;
  return React.useCallback(
    (date: FormatParameters[0], formatString: FormatParameters[1], options?: Omit<FormatParameters[2], "locale">) =>
      format(date, formatString, { ...options, locale: getDateFNSLocaleByLanguage(lang) }),
    [lang],
  );
}
