import React from "react";
import styled from "styled-components";

interface AbsoluteCenterInterface {
  children: React.ReactNode;
}

const AbsoluteCenterWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function AbsoluteCenter({ children }: AbsoluteCenterInterface) {
  return <AbsoluteCenterWrapper>{children}</AbsoluteCenterWrapper>;
}

export default React.memo(AbsoluteCenter);
