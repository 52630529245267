import { createAsyncAction, createAction } from "typesafe-actions";
import * as userConstants from "./constants";
import { UserInterface } from "./types";

export const getUserAsync = createAsyncAction(userConstants.REQUEST, userConstants.SUCCESS, userConstants.FAILED)<
  void,
  UserInterface,
  string
>();

export const initUser = createAction(userConstants.INIT_USER)<UserInterface>();

export const resetUser = createAction(userConstants.RESET_USER)();
