import { createAction, createAsyncAction } from "typesafe-actions";

import * as statisticsConstants from "./constants";
import { StatisticsType } from "./mapper";

export const resetState = createAction(statisticsConstants.RESET)();

export const getStatisticsAsync = createAsyncAction(
  statisticsConstants.REQUEST,
  statisticsConstants.SUCCESS,
  statisticsConstants.FAILED,
)<{ date_after: string; date_before: string; place: string }, StatisticsType, string>();
