import styled from "styled-components";
import TypographyLink, { TypographyLinkInterface } from "primitives/Typography/TypographyLink";
import { fontWeight } from "primitives/Typography/internal/style-helpers";

import { StyledMenuIcon } from "../MenuIcon";

interface MenuLinkProps {
  active?: boolean;
}

export default styled(TypographyLink).attrs({ type: "h4-regular", linkTheme: "black" })<
  MenuLinkProps & TypographyLinkInterface
>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ active, theme }) => active && [`color: ${theme.colors.black};`, fontWeight(500)]};
  flex: 1;

  &.main-menu-item {
    padding: 21px 0;
  }

  & > ${StyledMenuIcon} {
    margin-right: 15px;
  }

  &:hover svg path {
    ${({ active, theme }) => !active && `stroke: ${theme.colors.black};`};
  }
`;
