import { createReducer } from "typesafe-actions";

import { getStatisticsAsync, resetState } from "./actions";
import { State } from "./mapper";

const initialState: State = {
  isLoading: false,
  wasLoaded: false,
  data: null!,
};

const reducer = createReducer(initialState)
  .handleAction(getStatisticsAsync.request, (state) => ({
    ...state,
    isLoading: true,
    wasLoaded: false,
  }))
  .handleAction(getStatisticsAsync.success, (state, { payload }) => ({
    ...state,
    data: payload,
    isLoading: false,
    wasLoaded: true,
  }))
  .handleAction(getStatisticsAsync.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
  }))
  .handleAction(resetState, () => initialState);

export { reducer as statisticsReducer };
