import { createReducer } from "typesafe-actions";
import { getDocuments, resetState } from "./actions";
import { DocumentInterface } from "./types";
import { InitialState } from "../../types";

const initialState: InitialState<DocumentInterface[]> & {
  isError: boolean;
} = {
  isLoading: false,
  wasLoaded: false,
  data: [],
  isError: false,
};

const reducer = createReducer(initialState)
  .handleAction(getDocuments.request, (state) => ({
    ...state,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getDocuments.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: [...state.data, ...action.payload.data],
  }))
  .handleAction(getDocuments.failure, (state) => ({
    ...state,
    isError: true,
  }))
  .handleAction(resetState, () => {
    return initialState;
  });

export { reducer as documentsReducer };
