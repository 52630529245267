import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getMerchantContext, initContext } from "store/merchantContext/actions";
import { MerchantResponse } from "store/merchantContext/types";

export function* contextRequest() {
  yield takeEvery(getMerchantContext.request, function* (action) {
    try {
      const answer: MerchantResponse = yield loaderManager.get(apiUrls.merchantContextUrl, action.payload);
      if (answer.success) {
        yield put(getMerchantContext.success(answer.data));
      } else {
        yield put(getMerchantContext.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getMerchantContext.failure(e as string));
    }
  });
}

export function* contextSuccess() {
  yield takeEvery(getMerchantContext.success, function* (action) {
    yield put(initContext(action.payload));
  });
}

export function* contextFailed() {
  yield takeEvery(getMerchantContext.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* userSaga() {
  yield all([fork(contextRequest), fork(contextFailed), fork(contextSuccess)]);
}
