import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getAddons } from "./actions";
import { AddonsResponse } from "./types";

export function* addonsRequest() {
  yield takeEvery(getAddons.request, function* ({ payload }) {
    try {
      const answer: AddonsResponse = yield loaderManager.get(apiUrls.stopListAddons(), payload);
      if (answer.success) {
        yield put(getAddons.success({ data: answer.data, meta: answer.meta! }));
      } else {
        yield put(getAddons.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getAddons.failure(e as string));
    }
  });
}

export function* addonsFailed() {
  yield takeEvery(getAddons.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* addonsSaga() {
  yield all([fork(addonsRequest), fork(addonsFailed)]);
}
