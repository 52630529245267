import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";

import { globalEventBus } from "helpers/globalEventBus";
import { getSchedule } from "./actions";
import { WorkSchedule } from "./types";
import overridesSaga from "./overrides/saga";
import { ResponseFailType, ResponseSuccessType } from "../../types";

export function* scheduleRequest() {
  yield takeEvery(getSchedule.request, function* ({ payload }) {
    try {
      const answer: ResponseFailType & ResponseSuccessType<WorkSchedule> = yield loaderManager.get(
        apiUrls.schedule(payload),
      );
      if (answer.success) {
        yield put(getSchedule.success(answer.data));
      } else {
        yield put(getSchedule.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getSchedule.failure(e as string));
    }
  });
}

export function* scheduleFailed() {
  yield takeEvery(getSchedule.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* scheduleSaga() {
  yield all([fork(scheduleRequest), fork(scheduleFailed), fork(overridesSaga)]);
}
