import { createAsyncAction, createAction } from "typesafe-actions";
import { ResponseMetaData } from "types";

import { StopListService, StopListServicesRequest } from "./types";
import { StopListType } from "../addons/types";
import * as servicesConstants from "./constants";

export const getServices = createAsyncAction(
  servicesConstants.REQUEST_SERVICES,
  servicesConstants.SUCCESS_SERVICES,
  servicesConstants.FAILED_SERVICES,
)<StopListServicesRequest, { data: StopListService[]; meta: ResponseMetaData }, string>();

export const resetServices = createAction(servicesConstants.RESET_SERVICES)();

export const changeServiceType = createAction(servicesConstants.CHANGE_SERVICE_TYPE)<{
  id: string;
  stop_list_type: StopListType;
}>();
