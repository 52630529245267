import React from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import TypographyLink from "primitives/Typography/TypographyLink";
import Typography from "primitives/Typography";
import { useMerchantContext } from "store/merchantContext/hooks";
import { useLogout } from "store/auth/hooks";
import { useSelf } from "store/self/hooks";
import { useLogEvent } from "hooks/analytics";
import { useTranslation } from "hooks/i18n";
import { formatPhone } from "utils/numbers";
import { EXTERNAL_LINKS } from "constants/urls";

import Menu from "./MenuList";
import MenuItem from "./MenuItem";
import MenuLink from "./MenuLink";
import MenuIcon from "./MenuIcon";
import Language from "./Language";
import { mainMenu, subMenu } from "./libs/menuContent";
import { generateMenuByPermissions } from "./libs/generateMenuByPermissions";

const MerchantInfo = styled.div`
  margin-top: 20px;
  margin-bottom: 43px;
  display: flex;
  flex-direction: column;
`;

const Text = styled(Typography).attrs({ color: "secondary", type: "h4-regular" })`
  margin: 0 0 13px;
`;

const MerchantLink = styled(TypographyLink)`
  margin-bottom: 4px;
`;

const SubMenu = styled.ul`
  margin: 0;
  padding: 23px 0 0;
  border-bottom: 0;
`;

const SubMenuItem = styled(MenuItem)`
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
`;

function MenuContainer() {
  const merchantContext = useMerchantContext();
  const { t } = useTranslation("common");
  const self = useSelf();
  const [, logEventFabric] = useLogEvent();

  const mainMenuFiltered = React.useMemo(() => generateMenuByPermissions(self, mainMenu), [self]);
  const subMenuFiltered = React.useMemo(() => generateMenuByPermissions(self, subMenu), [self]);
  const mainMenuWithoutExternalLink = React.useMemo(
    () => !mainMenuFiltered.find((menu) => menu.newWindow),
    [mainMenuFiltered],
  );

  const logout = useLogout();
  const router = useRouter();

  return (
    <>
      {mainMenuFiltered.length > 0 && (
        <Menu withRightMargin={mainMenuWithoutExternalLink}>
          {mainMenuFiltered.map(({ key, icon, href, eventName, newWindow, external }, idx) => {
            const isActive = router.pathname === href;
            return (
              <MenuItem key={idx}>
                <MenuLink
                  href={href}
                  className="main-menu-item"
                  active={isActive}
                  target={newWindow ? "_blank" : undefined}
                  showExternalIcon={external}
                  onClick={logEventFabric(eventName!)}
                >
                  <MenuIcon type={icon!} active={isActive} />
                  {t(`menu.${key}`)}
                </MenuLink>
              </MenuItem>
            );
          })}
        </Menu>
      )}
      <Language />
      <SubMenu>
        {subMenuFiltered.map(({ key, href, eventName }, idx) => (
          <SubMenuItem key={idx}>
            <MenuLink href={href} active={router.pathname === href} onClick={logEventFabric(eventName!)}>
              {t(`submenu.${key}`)}
            </MenuLink>
          </SubMenuItem>
        ))}
        <SubMenuItem>
          <MenuLink href={EXTERNAL_LINKS.deliveryPartnerPDF} target="_blank" showExternalIcon>
            {t(`submenu.promotion`)}
          </MenuLink>
        </SubMenuItem>
      </SubMenu>
      <MerchantInfo>
        <Text>{t("menu.support")}:</Text>
        <MerchantLink
          href={`tel:${merchantContext.merchant_support_phone}`}
          onClick={logEventFabric("Help_Call", { source: "side_menu" })}
        >
          {formatPhone(merchantContext.merchant_support_phone)}
        </MerchantLink>
        <MerchantLink
          href={`mailto:${merchantContext.merchant_support_email}`}
          onClick={logEventFabric("Help_Email", { source: "side_menu" })}
        >
          {merchantContext.merchant_support_email}
        </MerchantLink>
      </MerchantInfo>
      <MenuLink href={null!} className="logout-btn" onClick={logout}>
        <MenuIcon type="sign-out" />
        {t("menu.logout")}
      </MenuLink>
    </>
  );
}

export default React.memo(MenuContainer);
