import { all, fork, put, takeEvery } from "redux-saga/effects";
import { compose, head, map } from "ramda";
import { Coordinates } from "@bb/components";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { MerchantB2BResponse, DeliveryZonesB2BResponse } from "store/merchantB2B/types";
import { getB2BAsync, getDeliveryZones } from "store/merchantB2B/actions";

export function* merchantB2BRequest() {
  yield takeEvery(getB2BAsync.request, function* (action) {
    try {
      const limit = 20;
      const answer: MerchantB2BResponse = yield loaderManager.get(apiUrls.merchantListB2B, {
        offset: (action.payload.page - 1) * limit,
        limit,
      });
      if (answer.success) {
        yield put(
          getB2BAsync.success({
            merchantB2B: answer.data,
            hasNextPage: !!answer.meta?.next,
            page: action.payload.page,
          }),
        );
      } else {
        yield put(getB2BAsync.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getB2BAsync.failure(e as string));
    }
  });
}

export function* deliveryZonesB2BRequest() {
  yield takeEvery(getDeliveryZones.request, function* () {
    try {
      const answer: DeliveryZonesB2BResponse = yield loaderManager.get(apiUrls.getDeliveryZone);
      if (answer.success) {
        yield put(
          getDeliveryZones.success({
            deliveryZones: answer.data.map((zone) => {
              const coordinates = compose(
                map(([latitude, longitude]: [number, number]): Coordinates => ({ latitude, longitude })),
                head,
              )(zone.geometry.coordinates);

              return {
                ...zone,
                geometry: {
                  ...zone.geometry,
                  coordinates,
                },
              };
            }),
          }),
        );
      } else {
        yield put(getB2BAsync.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getB2BAsync.failure(e as string));
    }
  });
}

export function* merchantB2BFailed() {
  yield takeEvery(getB2BAsync.failure, function* (action) {
    yield globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* merchantB2BSaga() {
  yield all([fork(merchantB2BRequest), fork(deliveryZonesB2BRequest), fork(merchantB2BFailed)]);
}
