import React from "react";

import Loading from "../Loading";
import LoadingError from "../LoadingError";
import AbsoluteCenter from "../AbsoluteCenter";

export interface NetworkStateInterface {
  absolutePositioning?: boolean;
  isLoading: boolean;
  wasLoaded: boolean;
  isError?: boolean;
  errorHandler(): void;
}

type NetworkStateComponentInterface = NetworkStateInterface & {
  children: React.ReactNode;
};

function NetworkState({
  children,
  errorHandler,
  isError,
  isLoading,
  wasLoaded,
  absolutePositioning = true,
}: NetworkStateComponentInterface) {
  let state: React.ReactElement | undefined;

  if (!wasLoaded) {
    if (isLoading) state = <Loading />;
    if (isError) state = <LoadingError onReload={errorHandler} />;
  }

  if (state) return absolutePositioning ? <AbsoluteCenter>{state}</AbsoluteCenter> : state;

  if (wasLoaded) return children as JSX.Element;

  return null;
}

export default React.memo(NetworkState);
