import { DocumentTypes } from "constants/documents";

class ApiUrls {
  constructor(private apiVersion: string) {}

  get merchantContextUrl(): string {
    return this.apiVersion + "/merchant/context";
  }

  get authMerchantUrl(): string {
    return this.apiVersion + "/auth/merchant";
  }

  get selfMerchantUrl(): string {
    return this.apiVersion + "/merchant/self";
  }

  get refreshTokenUrl(): string {
    return this.apiVersion + "/auth/refresh";
  }

  get authMerchantResetPassUrl(): string {
    return this.apiVersion + "/auth/merchant/reset-password/init";
  }

  get authMerchantSetPassUrl(): string {
    return this.apiVersion + "/auth/merchant/reset-password/set";
  }

  get checkInviteUrl(): string {
    return this.apiVersion + "/auth/merchant/invite";
  }

  get acceptInviteUrl(): string {
    return this.apiVersion + "/auth/merchant/invite.accept";
  }

  get merchantListPlaces(): string {
    return this.apiVersion + "/merchant/places/legal";
  }

  get merchantListB2B(): string {
    return this.apiVersion + "/merchant/places/b2b";
  }

  get verifyMerchantOrder(): string {
    return this.apiVersion + "/merchant/orders/verify";
  }

  get createMerchantOrder(): string {
    return this.apiVersion + "/merchant/orders/create";
  }

  get getMerchantPaymentCards() {
    return this.apiVersion + "/payments/cards";
  }

  get merchantEmployees(): string {
    return this.apiVersion + "/merchant/employees";
  }

  get sendMerchantInviteUrl(): string {
    return this.apiVersion + "/auth/merchant/invite";
  }

  get getDeliveryZone() {
    return this.apiVersion + "/merchant/places/delivery-zone";
  }

  merchantEmployeeEdit(id: string): string {
    return this.apiVersion + "/merchant/employees/{id}/change_role".replace("{id}", id);
  }
  merchantEmployeeActivate(id: string): string {
    return this.apiVersion + "/merchant/employees/{id}/activate".replace("{id}", id);
  }
  merchantEmployeeDeactivate(id: string): string {
    return this.apiVersion + "/merchant/employees/{id}/deactivate".replace("{id}", id);
  }
  merchantOrderList(state: string): string {
    return this.apiVersion + "/merchant/orders/list/{state}".replace("{state}", state);
  }
  merchantOrdersSummary(): string {
    return this.apiVersion + "/merchant/orders/list/history/summary";
  }
  stopListAddons(): string {
    return this.apiVersion + "/merchant/stop-list/addon-types";
  }
  stopListCategories(): string {
    return this.apiVersion + "/merchant/stop-list/categories";
  }
  stopListServices(): string {
    return this.apiVersion + "/merchant/stop-list/services";
  }
  stopListServiceType(id: string) {
    return this.apiVersion + "/merchant/stop-list/services/{id}".replace("{id}", id);
  }
  stopListAddonType(id: string) {
    return this.apiVersion + "/merchant/stop-list/addons/{id}".replace("{id}", id);
  }
  orderDetails(id: string) {
    return this.apiVersion + "/merchant/screens/order_details/{id}".replace("{id}", id);
  }
  orderCourierTrack(orderId: string) {
    return this.apiVersion + "/merchant/orders/{id}/couriers/track".replace("{id}", orderId);
  }
  courierMobile(orderId: string, courierId: string) {
    return (
      this.apiVersion +
      "/merchant/orders/{order_id}/courier_contact_info/{courier_id}"
        .replace("{order_id}", orderId)
        .replace("{courier_id}", courierId)
    );
  }
  clientContactInfo(orderId: string) {
    return this.apiVersion + "/merchant/orders/{order_id}/client_contact_info".replace("{order_id}", orderId);
  }
  activeOrdersWidgets(placeId: string) {
    return this.apiVersion + "/merchant/screens/active_orders/{place_id}".replace("{place_id}", placeId);
  }
  placeWorkload(placeId: string) {
    return this.apiVersion + "/merchant/places/{id}/workload".replace("{id}", placeId);
  }
  updateOrderState(orderId: string, state: string) {
    return this.apiVersion + "/merchant/orders/{id}/{state}".replace("{id}", orderId).replace("{state}", state);
  }
  updateOrderStateLegacy(state: string) {
    return this.apiVersion + "/merchant/orders/{state}".replace("{state}", state);
  }
  sendOrdersReport() {
    return this.apiVersion + "/merchant/orders/generate-report";
  }
  schedule(placeId: string) {
    return this.apiVersion + "/merchant/places/{id}/schedule".replace("{id}", placeId);
  }
  getScheduleOverride(placeId: string) {
    return this.apiVersion + "/merchant/places/{id}/schedule/override".replace("{id}", placeId);
  }
  mutateScheduleOverride(id: string) {
    return this.apiVersion + "/merchant/places/schedule/override/{id}".replace("{id}", id);
  }
  postScheduleOverride() {
    return this.apiVersion + "/merchant/places/schedule/override";
  }
  setPushToken() {
    return this.apiVersion + "/user/push/set";
  }
  getMerchantPlacesSummary() {
    return this.apiVersion + "/merchant/places/summary";
  }
  getMerchantStatistics() {
    return this.apiVersion + "/merchant/statistics";
  }
  checkCoordinates() {
    return this.apiVersion + "/check.coordinates";
  }
  getPublicOfferRequest() {
    return this.apiVersion + "/places/public-offer/request";
  }
  getSubscriptionOfferRequest() {
    return this.apiVersion + "/places/public-offer/subscription-request";
  }
  getCuisineListRequest() {
    return this.apiVersion + "/tags?type=cuisine&limit=100";
  }
  getPlaceCategoriesRequest() {
    return this.apiVersion + "/places/place.categories";
  }
  getOfferb2bPublicOffer() {
    return this.apiVersion + "/places/public-offer/b2b-request";
  }
  getMerchantDocumentList() {
    return this.apiVersion + "/places/documents";
  }
  logout() {
    return this.apiVersion + "/auth/logout";
  }
}

export const apiUrls = new ApiUrls(process.env.NEXT_PUBLIC_API_VERSION!);
