import firebaseRoot from "firebase";
import "firebase/messaging";
import { firebase } from "configs/firebase";
import { globalEventBus, NotificationPushEventPayload } from "helpers/globalEventBus";

let messaging: firebaseRoot.messaging.Messaging = null!;

if (process.browser) {
  try {
    messaging = firebase.messaging();
  } catch (e) {}
}

export const getToken = async () => {
  if (messaging) {
    return await messaging.getToken();
  }
};

export const subscribe = () => {
  if (messaging) {
    messaging.onMessage((payload: { notification: NotificationPushEventPayload["message"] }) => {
      try {
        const audio = new Audio("/sound_push.mp3");
        audio.play();
      } catch (e) {}

      globalEventBus.emit("NOTIFICATION_PUSH", { message: payload.notification });
    });
  }
};

export const unsubscribe = async () => {
  if (messaging) {
    await messaging.deleteToken();
  }
};
