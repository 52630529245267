import merge from "lodash/merge";

export let theme = {
  colors: {
    transparent: "transparent",
    primary: "#F37021",
    primaryHover: "#E86B1F",
    primaryOpacity: "rgba(243, 112, 33, .5)",
    activeTab: "#FFF3EC",
    secondary: "#B6B6B6",
    success: "#3CB839",
    warning: "#E86868",
    danger: "#CB3506",
    yellow: "#F39F21",
    ligh_green: "#E9FFE9",
    lightBlue: "#0096EA",
    tooltipBg: "#616161",
    black: "#000000",
    white: "#FFFFFF",
    hint: "#808080",
    disabled_bg: "#F2F2F2",
    disabled_text: "#CCCCCC",
    border: "#E5E5E5",
    borderHover: "#d5d5d5",
    secondary_btn: "#E5E5E5",
    fieldsHeader: "#747474",
    appBackground: "#F9F9F9",
    bg_light: "#F8F8F8",
    tableRowBg: "#F3F3F3",
    modalCloseBg: "#F3F3F3",
    bg_red: "#FFE4E4",
  },
  ui: {
    radius_xs: "4px",
    radius_s: "8px",
    radius_m: "12px",
    radius_l: "24px",
  },
  typography: {
    fw_normal: "400",
    fw_semi: "500",
    fw_bold: "700",
    fsz_xxs: "0.75rem",
    fsz_xs: "0.875rem",
    fsz_s: "1rem",
    fsz_m: "1.115rem",
    fsz_l: "1.5rem",
    fsz_xl: "2rem",
    fsz_xxl: "2.5rem",
    lh_xs: "0.875rem",
    lh_s: "1.25rem",
  },
  breakpoints: {
    greaterThanMobileBreakpoint: `(min-width: 600px)`,
    appMobileBreakpoint: `(max-width: 600px)`,
  },
};

export type Theme = typeof theme;
export type ThemeColors = keyof Theme["colors"];

if (process.env.NEXT_PUBLIC_MODE === "KRYM_EDA") {
  theme = merge(theme, {
    colors: {
      primary: "#5011EA",
      primaryHover: "#4809F5",
      activeTab: "rgba(80, 17, 234, 0.05)",
      primaryOpacity: "rgba(80, 17, 234, 0.5)",
    },
  });
}
