import { CategoryInterface } from "./types";
import { createAction, createAsyncAction } from "typesafe-actions";
import * as categoriesConstants from "./constants";

export const getCategories = createAsyncAction(
  categoriesConstants.REQUEST_CATEGORIES,
  categoriesConstants.SUCCESS_CATEGORIES,
  categoriesConstants.FAILED_CATEGORIES,
)<
  {
    place: string;
  },
  CategoryInterface[],
  string
>();

export const addCategoryServiceToStopList = createAction(categoriesConstants.BLOCK_CATEGORY_SERVICE)<string>();

export const removeCategoryServiceFromStopList = createAction(categoriesConstants.UNBLOCK_CATEGORY_SERVICE)<string>();
