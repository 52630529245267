import { AddonsState } from "./types";
import { createReducer } from "typesafe-actions";
import { getAddons, resetAddons, changeAddonType } from "./actions";

const initialState: AddonsState = {
  isLoading: false,
  wasLoaded: false,
  isError: false,
  data: [],
  meta: {
    count: 0,
    next: "",
    previous: "",
  },
};

const reducer = createReducer(initialState)
  .handleAction(getAddons.request, (state) => ({
    ...state,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getAddons.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: [...state.data, ...action.payload.data],
    meta: {
      ...state.meta,
      ...action.payload.meta,
    },
  }))
  .handleAction(changeAddonType, (state, { payload }) => ({
    ...state,
    data: state.data.map((addon) => {
      return {
        ...addon,
        addons: addon.addons.map((addonType) => {
          if (addonType.id === payload.id) {
            return {
              ...addonType,
              stop_list_type: payload.stop_list_type,
            };
          }
          return addonType;
        }),
      };
    }),
  }))
  .handleAction(getAddons.failure, (state) => ({
    ...state,
    isError: true,
  }))
  .handleAction(resetAddons, () => initialState);

export { reducer as addonsReducer };
