import { createReducer } from "typesafe-actions";
import { initUser, resetUser, getUserAsync } from "./actions";
import { UserState } from "store/self/types";

const initialState: UserState = {
  wasLoaded: false,
  isLoading: false,
  data: null!,
};

const reducer = createReducer(initialState)
  .handleAction(getUserAsync.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(getUserAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    data: action.payload,
  }))
  .handleAction(getUserAsync.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
  }))
  .handleAction(initUser, (state, action) => ({
    ...state,
    data: action.payload,
  }))
  .handleAction(resetUser, () => initialState);

export { reducer as userReducer };
