import { createReducer } from "typesafe-actions";

import { PlaceInterface } from "containers/Pages/Offerb2b/types";

import { CurrentStepInterface, Offerb2bDadataInterface, PlaceCategoriesInterface } from "./types";
import { fetchDadata, fetchPlaceCategories, setCurrentStep, setLoading, setPlaces } from "./actions";

const initialState: {
  isLoading: boolean;
  currentStep: CurrentStepInterface;
  dadata: Offerb2bDadataInterface;
  placeCategories: PlaceCategoriesInterface[];
  places: PlaceInterface[];
} = {
  isLoading: false,
  currentStep: 1,
  dadata: null!,
  placeCategories: [],
  places: [],
};

const reducer = createReducer(initialState)
  .handleAction(fetchDadata.success, (state, { payload }) => ({
    ...state,
    isLoading: false,
    dadata: payload,
    currentStep: 2,
  }))
  .handleAction(fetchPlaceCategories.success, (state, { payload }) => ({
    ...state,
    placeCategories: payload,
  }))
  .handleAction(setCurrentStep, (state, { payload }) => ({
    ...state,
    currentStep: payload,
  }))
  .handleAction(setLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  }))
  .handleAction(setPlaces, (state, { payload }) => ({
    ...state,
    places: payload,
  }));

export { reducer as offerb2bReducer };
