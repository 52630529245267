import React from "react";
import { Normalize } from "styled-normalize";
import PopupGlobalStyles from "primitives/Popup/PopupGlobalStyles";
import DatePickerGlobalStyles from "primitives/Inputs/DatePicker/DatePickerGlobalStyles";
import { ScrollGlobalStyles } from "primitives/Scroll/styled";

import { GlobalCustomStyles } from "./globals";

export function GlobalStyles() {
  return (
    <>
      <Normalize />
      <GlobalCustomStyles />
      <PopupGlobalStyles />
      <DatePickerGlobalStyles />
      <ScrollGlobalStyles />
    </>
  );
}
