import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelfSelector, selfIsLoading, selfWasLoaded } from "store/self/selectors";
import { getUserAsync } from "store/self/actions";
import { CommonPermissionKeys, commonPermissions } from "constants/permissions";

import { UserInterface } from "./types";

export function useSelf() {
  return useSelector(getSelfSelector);
}

export function useSelfLoader() {
  const self = useSelf();
  const dispatch = useDispatch();
  useEffect(() => {
    if (self) return;
    dispatch(getUserAsync.request());
  }, [dispatch, self]);

  const wasLoaded = useSelector(selfWasLoaded);
  const isLoading = useSelector(selfIsLoading);
  const errorHandler = React.useCallback(() => dispatch(getUserAsync.request()), [dispatch]);

  return { self, wasLoaded, isLoading, errorHandler };
}

export function checkUserPermission(self: UserInterface, commonPermissionKey: CommonPermissionKeys) {
  return commonPermissions[commonPermissionKey].some((right) => {
    if (right.startsWith("!")) return !self.permissions.includes(right.slice(1));
    return self.permissions.includes(right);
  });
}
