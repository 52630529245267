export const namespace = "@auth";

export const REQUEST = "@auth/REQUEST";
export const FAILED = "@auth/FAILED";
export const SUCCESS = "@auth/SUCCESS";

export const INIT_AUTH = "@auth/INIT_AUTH";
export const RESTORE_TOKENS = "@auth/RESTORE_TOKENS";

export const REFRESH_TOKENS_SUCCESS = "@auth/REFRESH_TOKENS";
export const REFRESH_TOKENS_FAILED = "@auth/REFRESH_TOKENS_FAILED";

export const RESET_AUTH = "@auth/RESET_AUTH";
export const LOGOUT = "@auth/LOGOUT";
