import { createAsyncAction, createAction } from "typesafe-actions";

import * as b2bConstants from "./constants";
import { DeliveryZone, MerchantB2BInterface } from "./types";

export const getB2BAsync = createAsyncAction(b2bConstants.REQUEST, b2bConstants.SUCCESS, b2bConstants.FAILED)<
  { page: number },
  { merchantB2B: MerchantB2BInterface[]; hasNextPage: boolean; page: number },
  string
>();

export const getDeliveryZones = createAsyncAction(
  b2bConstants.DELIVERY_ZONES_REQUEST,
  b2bConstants.DELIVERY_ZONES_SUCCESS,
  b2bConstants.DELIVERY_ZONES_FAILED,
)<undefined, { deliveryZones: DeliveryZone[] }, string>();

export const resetB2B = createAction(b2bConstants.RESET_B2B)();
