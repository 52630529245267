import { CommonPermissionKeys } from "constants/permissions";
import { EXTERNAL_LINKS, urls } from "constants/urls";

export type MenuItem = {
  key: string;
  icon?: string;
  href: string;
  hide?: boolean;
  commonPermissionKey: CommonPermissionKeys;
  eventName?: string;
  newWindow?: boolean;
  external?: boolean;
};

export const mainMenu: MenuItem[] = [
  {
    key: "orders",
    icon: "orders",
    href: urls.activeOrders.url,
    commonPermissionKey: "activeOrders",
    eventName: "Menu_Active_Orders",
  },
  {
    key: "b2b",
    icon: "courier",
    href: urls.b2b.url,
    commonPermissionKey: "b2b",
    eventName: "Menu_RequestCourier",
  },
  {
    key: "b2b_promo",
    icon: "courier",
    href: EXTERNAL_LINKS.b2bPromoPDF,
    commonPermissionKey: "b2bPromo",
    eventName: "Menu_B2BPromo",
    newWindow: true,
    external: true,
  },
  {
    key: "statistics",
    icon: "stats",
    href: urls.dashboard.url,
    commonPermissionKey: "statistics",
    eventName: "Menu_Statistics",
  },
  {
    key: "history",
    icon: "history",
    href: urls.history.url,
    commonPermissionKey: "history",
    eventName: "Menu_History_Orders",
  },
  {
    key: "stopList",
    icon: "stop",
    href: urls.stopList.url,
    commonPermissionKey: "stopList",
    eventName: "Menu_Stop_List",
  },
  {
    key: "menu",
    icon: "menu",
    href: "#",
    hide: true,
    commonPermissionKey: "menu",
  },
  {
    key: "schedule",
    icon: "schedule",
    href: urls.schedule.url,
    commonPermissionKey: "schedule",
    eventName: "Menu_Schedule",
  },
  {
    key: "employees",
    icon: "members",
    href: urls.staff.url,
    commonPermissionKey: "employees",
    eventName: "Menu_Staff",
  },
  {
    key: "documents",
    icon: "documents",
    href: urls.documents.url,
    commonPermissionKey: "documents",
    eventName: "Menu_Documents",
  },
];

export const subMenu: MenuItem[] = [
  {
    key: "legal",
    href: urls.legal.url,
    commonPermissionKey: "legal",
    eventName: "Menu_Legal",
  },
  {
    key: "faq",
    href: "#",
    hide: true,
    commonPermissionKey: "questions",
  },
];
