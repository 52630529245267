import { createReducer } from "typesafe-actions";
import { InitialState } from "types";

import { getPlacesAsync, resetPlaces } from "./actions";
import { MultiPlaceInterface } from "./types";

const initialState: InitialState<MultiPlaceInterface[]> & {
  page: number;
  loadMoreLoading: boolean;
  hasNextPage: boolean;
} = {
  isLoading: false,
  wasLoaded: false,
  data: [],
  page: 1,
  loadMoreLoading: false,
  hasNextPage: false,
};

const reducer = createReducer(initialState)
  .handleAction(getPlacesAsync.request, (state, { payload }) => ({
    ...state,
    page: payload.page,
    loadMoreLoading: payload.page !== 1,
    isLoading: payload.page === 1,
  }))
  .handleAction(getPlacesAsync.success, (state, { payload }) => ({
    ...state,
    data: payload.page === 1 ? payload.merchantPlaces : [...state.data, ...payload.merchantPlaces],
    hasNextPage: payload.hasNextPage,
    isLoading: false,
    loadMoreLoading: false,
    wasLoaded: true,
  }))
  .handleAction(getPlacesAsync.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
  }))
  .handleAction(resetPlaces, () => initialState);

export { reducer as merchantPlacesReducer };
