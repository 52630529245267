import styled from "styled-components";
import Typography from "primitives/Typography";

import { ModalSize } from "../types";

const modalWidthBySize: Record<ModalSize, string | number> = {
  [ModalSize.ADJUST_CONTENT]: "auto",
  [ModalSize.SMALL]: "375px",
  [ModalSize.MEDIUM]: "472px",
  [ModalSize.LARGE]: "570px",
  [ModalSize.EXTRA]: "700px",
};

export const StyledModalContent = styled.div<{ size: ModalSize; excludePaddings?: boolean }>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  max-width: calc(100% - 80px);
  max-height: calc(100% - 40px);
  width: ${({ size }) => modalWidthBySize[size]};
  background-color: ${({ theme }) => theme.colors.white};
  text-align: left;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  overflow-x: hidden;
  overflow-y: hidden;
  ${({ excludePaddings }) => !excludePaddings && "padding: 24px 24px 32px"};

  @media (max-width: 768px) {
    overflow-y: auto;
  }
`;

export const ModalContentButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 20px;
`;

export const ModalContentTitle = styled(Typography).attrs({ type: "h3-bold" })`
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
`;

export const StyledModal = styled.div<{ forceTop?: boolean }>`
  position: fixed;
  inset: 0;
  min-height: 100vh;
  max-height: 100vh;
  z-index: ${({ forceTop }) => (forceTop ? 2 : 1)};
  background-color: rgba(0, 0, 0, 0.16);
  text-align: center;
`;

export const StyledModalVerticalCenterHeightBalancer = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;
