import { createAsyncAction, createAction } from "typesafe-actions";
import * as scheduleConstants from "./constants";
import * as overridesActions from "./overrides/actions";
import { WorkSchedule } from "./types";

export const getSchedule = createAsyncAction(
  scheduleConstants.REQUEST,
  scheduleConstants.SUCCESS,
  scheduleConstants.FAILED,
)<string, WorkSchedule, string>();

export const resetSchedule = createAction(scheduleConstants.RESET)();

export { overridesActions };
