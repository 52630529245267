import React from "react";
import { isDeepEqual, isPureObject } from "@worksolutions/utils";
import { ThemeColors } from "styles/theme";

import InternalSvg from "./variants/InternalSvg";
import { internalIcons } from "./list";

export type InternalIcons = keyof typeof internalIcons;

export interface IconInterface {
  className?: string;
  icon: InternalIcons | string;
  width?: number | string;
  height?: number | string;
  color?: ThemeColors;
}

function isInternalIcon(icon: string): icon is InternalIcons {
  return isPureObject(internalIcons[icon as InternalIcons]);
}

function Icon({ icon, color = "secondary", ...props }: IconInterface) {
  if (!icon) return null;

  if (isInternalIcon(icon)) return <InternalSvg icon={icon} color={color} {...props} />;

  return null;
}

export default React.memo(Icon, isDeepEqual);
