export const namespace = "@subscription";

export const SET_CURRENT_STEP = "@subscription/SET_CURRENT_STEP";
export const SET_PLACE = "@subscription/SET_PLACE";
export const SET_TOKEN_DATA = "@subscription/SET_TOKEN_DATA";

export const REQUEST_COORDS = "@subscription/COORDS_REQUEST";
export const FAILED_COORDS = "@subscription/COORDS_FAILED";
export const SUCCESS_COORDS = "@subscription/COORDS_SUCCESS";

export const REQUEST_PLACE_CUISINES = "@subscription/PLACE_CUISINES_REQUEST";
export const SUCCESS_PLACE_CUISINES = "@subscription/PLACE_CUISINES_SUCCESS";
export const FAILED_PLACE_CUISINES = "@subscription/PLACE_CUISINES_FAILED";

export const REQUEST_DADATA = "@subscription/DADATA_REQUEST";
export const SUCCESS_DADATA = "@subscription/DADATA_SUCCESS";
export const FAILED_DADATA = "@subscription/DADATA_FAILED";

export const REQUEST_SUBSCRIPTION_OFFER = "@subscription/SUBSCRIPTION_OFFER_REQUEST";
export const SUCCESS_SUBSCRIPTION_OFFER = "@subscription/SUBSCRIPTION_OFFER_SUCCESS";
export const FAILED_SUBSCRIPTION_OFFER = "@subscription/SUBSCRIPTION_OFFER_FAILED";
