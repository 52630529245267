import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";

import { getOrderDetails } from "./actions";
import { OrderDetailsResponse } from "./types";

export function* orderDetailsRequest() {
  yield takeEvery(getOrderDetails.request, function* ({ payload }) {
    try {
      const answer: OrderDetailsResponse = yield loaderManager.get(apiUrls.orderDetails(payload));
      if (answer.success) {
        yield put(
          getOrderDetails.success({
            data: answer.data,
            metaData: {
              serverTime: parseInt(answer.__headers["x-server-time"], 10),
            },
          }),
        );
      } else {
        yield put(getOrderDetails.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getOrderDetails.failure(e as string));
    }
  });
}

export function* orderDetailsFailed() {
  yield takeEvery(getOrderDetails.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* orderDetailsSaga() {
  yield all([fork(orderDetailsRequest), fork(orderDetailsFailed)]);
}
