import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getPlacesAsync } from "store/legal/actions";
import { MerchantPlacesResponse } from "store/legal/types";

export function* merchantPlacesRequest() {
  yield takeEvery(getPlacesAsync.request, function* (action) {
    try {
      const answer: MerchantPlacesResponse = yield loaderManager.get(apiUrls.merchantListPlaces);
      if (answer.success) {
        yield put(getPlacesAsync.success(answer.data));
      } else {
        yield put(getPlacesAsync.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getPlacesAsync.failure(e as string));
    }
  });
}

export function* merchantPlacesFailed() {
  yield takeEvery(getPlacesAsync.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* merchantPlacesSaga() {
  yield all([fork(merchantPlacesRequest), fork(merchantPlacesFailed)]);
}
