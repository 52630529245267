import { createAsyncAction, createAction } from "typesafe-actions";
import { EditEmployeeInterface, EmployeeInterface } from "store/employees/types";

import * as employeeConstants from "./constants";

export const getEmployees = createAsyncAction(
  employeeConstants.REQUEST,
  employeeConstants.SUCCESS,
  employeeConstants.FAILED,
)<void, Array<EmployeeInterface>, string>();

export const initEmployees = createAction(employeeConstants.INIT_EMPLOYEES)<Array<EmployeeInterface>>();

export const editEmployee = createAction(employeeConstants.EDIT)<EditEmployeeInterface>();

export const changeActivateEmployee = createAction(employeeConstants.CHANGE_ACTIVATE)<{
  id: string;
  isActive: boolean;
}>();

export const resetEmployees = createAction(employeeConstants.RESET_EMPLOYEES)();
