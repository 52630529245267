import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tokenExist } from "store/auth/selectors";
import { restoreTokens } from "store/auth/actions";

interface AuthContainerInterface {
  children: JSX.Element;
}

export function InitAuthContainer({ children }: AuthContainerInterface) {
  const dispatch = useDispatch();
  const tokenIsRestored = useSelector(tokenExist);

  React.useEffect(() => {
    setTimeout(() => dispatch(restoreTokens()), 0);
  }, [dispatch]);

  if (tokenIsRestored) return children;
  return null;
}
