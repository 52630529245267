import { useEffect, useState } from "react";
import FingerPrint2, { Options } from "fingerprintjs2";

const FP_OPTIONS: Options = {
  excludes: {
    fonts: true,
    language: true,
    colorDepth: true,
    deviceMemory: true,
    pixelRatio: true,
    hardwareConcurrency: true,
    screenResolution: true,
    availableScreenResolution: true,
    timezoneOffset: true,
    timezone: true,
    sessionStorage: true,
    localStorage: true,
    indexedDb: true,
    openDatabase: true,
    plugins: true,
    adBlock: true,
    audio: true,
  },
};

export function useFingerprint() {
  const [deviceId, setDeviceId] = useState("");

  useEffect(() => {
    FingerPrint2.get(FP_OPTIONS, (components) => {
      const values = components.map((component) => component.value);
      const hash = FingerPrint2.x64hash128(values.join(""), 31);
      setDeviceId(hash);
    });
  }, []);

  return deviceId;
}
