export const urls = {
  login: {
    url: "/auth/login",
  },
  registration: {
    url: "/auth/invite",
  },
  restore: {
    url: "/auth/restore",
  },
  reset: {
    url: "/auth/reset",
  },
  dashboard: {
    url: "/app/dashboard",
  },
  b2b: {
    url: "/app/b2b",
  },
  staff: {
    url: "/app/staff",
  },
  documents: {
    url: "/app/documents",
  },
  legal: {
    url: "/app/legal",
  },
  history: {
    url: "/app/orders/history",
  },
  stopList: {
    url: "/app/stop-list",
  },
  activeOrders: {
    url: "/app/orders/active",
  },
  schedule: {
    url: "/app/schedule",
  },
  download: {
    url: "/download",
  },
  offer: {
    url: "/offer",
  },
  offerRequest: {
    url: "/offer/request",
  },
  offerCityRequest: {
    url: "/offer/city-request",
  },
  offerFinish: {
    url: "/offer/finish",
  },
  offerAgreement: {
    url: "/offer/agreement",
  },
  subscription: {
    url: "/subscription",
  },
  subscriptionRequest: {
    url: "/subscription/request",
  },
  subscriptionCityRequest: {
    url: "/subscription/city-request",
  },
  subscriptionFinish: {
    url: "/subscription/finish",
  },
  subscriptionAgreement: {
    url: "/subscription/agreement",
  },
  offerb2bFinish: {
    url: "/offerb2b/finish",
  },
  offerb2bAgreement: {
    url: "/offerb2b/agreement",
  },
};

export const defaultRedirect = urls.activeOrders;
export const MOBILE_TOKEN_EXPIRE_PAGE = "/broniboyTokenExpired";

export const EXTERNAL_LINKS = {
  b2bPromoPDF: "/Broniboy - направление B2B.pdf",
  deliveryPartnerPDF: "/Broniboy - партнер по доставке.pdf",
};
