import { all, fork, takeEvery } from "redux-saga/effects";
import * as firebaseActions from "./actions";

import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";

import { getToken, subscribe, unsubscribe } from "integrations/web-push";

export function* subscribeToWebPush() {
  yield takeEvery(firebaseActions.subscribe, function* () {
    try {
      if (Notification.permission === "granted") {
        // @ts-ignore
        const token = yield getToken();

        const storageFbToken = localStorage.getItem("fb_token");

        if (storageFbToken === token) {
          subscribe();
        } else {
          yield loaderManager.post(apiUrls.setPushToken(), null, {
            token,
          });
          localStorage.setItem("fb_token", token);
        }
      } else {
        const permission: NotificationPermission = yield Notification.requestPermission();
        if (permission === "granted") {
          // @ts-ignore
          const token = yield getToken();

          yield loaderManager.post(apiUrls.setPushToken(), null, {
            token,
          });

          subscribe();
        }
      }
    } catch (e) {}
  });
}

export function* unsubscribeWebPush() {
  yield takeEvery(firebaseActions.unSubscribe, function* () {
    try {
      localStorage.removeItem("fb_token");
      yield unsubscribe();
    } catch (e) {}
  });
}

export default function* firebaseSaga() {
  yield all([fork(subscribeToWebPush), fork(unsubscribeWebPush)]);
}
