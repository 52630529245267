import { ResponseMetaData } from "types/index";
import { Addon, AddonsRequest, StopListType } from "./types";
import { createAsyncAction, createAction } from "typesafe-actions";
import * as addonsConstants from "./constants";
export const getAddons = createAsyncAction(
  addonsConstants.REQUEST_ADDONS,
  addonsConstants.SUCCESS_ADDONS,
  addonsConstants.FAILED_ADDONS,
)<AddonsRequest, { data: Addon[]; meta: ResponseMetaData }, string>();

export const resetAddons = createAction(addonsConstants.RESET_ADDONS)();

export const changeAddonType = createAction(addonsConstants.CHANGE_ADDON_TYPE)<{
  id: string;
  stop_list_type: StopListType;
}>();
