import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getUserAsync } from "store/self/actions";
import { UserResponse } from "store/self/types";
import { setPlace } from "store/ui/actions";

export function* userRequest() {
  yield takeEvery(getUserAsync.request, function* () {
    try {
      const answer: UserResponse = yield loaderManager.get(apiUrls.selfMerchantUrl);
      if (answer.success) {
        yield put(getUserAsync.success(answer.data));
      } else {
        yield put(getUserAsync.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getUserAsync.failure(e as string));
    }
  });
}

export function* userRequestSuccess() {
  yield takeEvery(getUserAsync.success, function* ({ payload }) {
    try {
      window.amplitude.getInstance().setUserId(payload.id);
      window.amplitude.getInstance().setUserProperties({
        firstname: payload.name,
        lastname: payload.surname,
        email: payload.email,
      });
      const storedPlace = JSON.parse(localStorage.getItem("place")!);
      if (storedPlace) yield put(setPlace(storedPlace));
    } catch (e) {}
  });
}

export function* userFailed() {
  yield takeEvery(getUserAsync.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* userSaga() {
  yield all([fork(userRequest), fork(userRequestSuccess), fork(userFailed)]);
}
