import { KRYM_EDA, BRONIBOY, abbreviations } from "constants/partners";

let config: {
  authLogo: string;
  mainLogo: string;
  authBgLogo: string;
  favicon: string;
  faviconico: string;
  apple: string;
} = null!;

if (process.env.NEXT_PUBLIC_MODE === KRYM_EDA) {
  config = {
    authLogo: "/krym_logo.svg",
    mainLogo: "/krym-main-logo.svg",
    authBgLogo: "/krym-bg-logo.svg",
    favicon: "/favicon-krym.svg",
    faviconico: "/krym-eda.ico",
    apple: "/krym-eda-apple.png",
  };
}

if (process.env.NEXT_PUBLIC_MODE === BRONIBOY) {
  config = {
    authLogo: "/bb_logo.svg",
    mainLogo: "/bb-main-logo.svg",
    authBgLogo: "/background_flash.svg",
    favicon: "/favicon.svg",
    faviconico: "/favicon.ico",
    apple: "/apple-touch-icon.png",
  };
}

export const getCurrentPartner = () => process.env.NEXT_PUBLIC_MODE! as keyof typeof abbreviations;

export { config };
