import { all, fork, put, takeEvery } from "redux-saga/effects";
import { resetUser } from "store/self/actions";

import { setPlace } from "./actions";

export function* setPlaceWorker() {
  yield takeEvery(setPlace, function* ({ payload }) {
    try {
      localStorage.setItem("place", JSON.stringify(payload));
    } catch (e) {}
  });
}

export function* deletePlace() {
  yield takeEvery(resetUser, function* () {
    localStorage.removeItem("place");
    yield put(setPlace(null!));
  });
}

export default function* uiSaga() {
  yield all([fork(setPlaceWorker), fork(deletePlace)]);
}
