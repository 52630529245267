import { toast, ToastOptions } from "react-toastify";
import { NotificationPushEventPayload } from "helpers/globalEventBus";

const defaultToastOptions: Partial<ToastOptions> = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

export function showError(message: string, options?: ToastOptions) {
  toast.error(message, { ...defaultToastOptions, ...options });
}

export function showSuccess(message: string, options?: ToastOptions) {
  toast.success(message, { ...defaultToastOptions, ...options });
}

export function showPush({ body, title }: NotificationPushEventPayload["message"], options?: ToastOptions) {
  toast.info(
    <div>
      {title && (
        <>
          {title} <br />
        </>
      )}{" "}
      {body}
    </div>,
    { ...defaultToastOptions, ...options },
  );
}

export function showWarn(message: string, options?: ToastOptions) {
  toast.warning(message, { ...defaultToastOptions, ...options });
}
