import { createAsyncAction, createAction } from "typesafe-actions";

import * as overridesConstants from "./constants";
import { WorkScheduleOverride } from "./types";

export const getOverrides = createAsyncAction(
  overridesConstants.REQUEST,
  overridesConstants.SUCCESS,
  overridesConstants.FAILED,
)<string, WorkScheduleOverride[], string>();

export const resetOverrides = createAction(overridesConstants.RESET)();
