import React from "react";
import { useRouter } from "next/router";
import { ValueType } from "react-select/src/types";
import { useLanguageSetter } from "containers/LanguageSelector/useLanguage";
import SimpleSelect, { SimpleSelectInterface } from "primitives/Selects/SimpleSelect";
import { useTranslation } from "hooks/i18n";

type LanguageSelectorInterface = Omit<SimpleSelectInterface, "value" | "options" | "onChange">;

function LanguageSelector(props: LanguageSelectorInterface) {
  const { locales } = useRouter();
  const { language } = useTranslation();
  const setLanguage = useLanguageSetter();

  const value = React.useMemo(() => ({ value: language, label: language }), [language]);
  const options = React.useMemo(() => locales!.map((locale) => ({ value: locale, label: locale })), [locales]);

  // TODO: refactor types in select (ValueType)
  const handleChange = React.useCallback(
    (data: ValueType<{ label: string; value: string }, false>) => setLanguage(data!.value),
    [setLanguage],
  );

  if (options.length < 2) return null;

  return <SimpleSelect {...props} value={value} options={options} onChange={handleChange} />;
}

export default React.memo(LanguageSelector);
