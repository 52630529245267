import { globalEventBus } from "./globalEventBus";

export function handlerServerErrors(message: string | Record<string, Array<string>>) {
  let finalMessage = message;
  if (typeof message !== "string") {
    if (Array.isArray(message)) {
      finalMessage = message.join("\n");
    } else {
      for (const value of Object.values(message)) {
        globalEventBus.emit("NOTIFICATION_ERROR", { message: Array.isArray(value) ? value[0] : value });
      }
      return null;
    }
  }

  globalEventBus.emit("NOTIFICATION_ERROR", { message: finalMessage.toString() });
}
