import React from "react";
import ReactDOM from "react-dom";
import { useBoolean, useEffectSkipFirst } from "@worksolutions/react-utils";
import { isFunction } from "@worksolutions/utils";
import { isNil } from "ramda";

import ModalContent from "./ModalContent";
import { ModalInterface } from "./types";
import { StyledModal, StyledModalVerticalCenterHeightBalancer } from "./internal/styled";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export { ModalSize } from "./types";
export type { ModalInterface } from "./types";

function Modal({
  opened: openedProp,
  rootElement,
  onClose,
  onOpen,
  children: Children,
  WrapperComponent,
  forceTop,
  ...props
}: ModalInterface & { rootElement?: HTMLElement }) {
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const [opened, open, close] = useBoolean(() => (isNil(openedProp) ? false : openedProp));
  useEffectSkipFirst(() => (openedProp ? open() : close()), [openedProp, open, close]);

  const root = React.useMemo(() => (opened ? rootElement || Modal._rootElement : null), [opened, rootElement]);

  useEffectSkipFirst(() => {
    if (opened) {
      if (onOpen) onOpen();
      return;
    }

    if (onClose) onClose();
  }, [opened, onClose, onOpen]);

  useEffectSkipFirst(() => {
    if (!opened) return;
    const modalNode = modalRef.current;
    if (!modalNode) return;
    disableBodyScroll(modalNode);
    return () => enableBodyScroll(modalNode);
  }, [opened, onClose, onOpen]);

  React.useEffect(() => () => clearAllBodyScrollLocks(), []);

  return (
    <>
      {WrapperComponent && (isFunction(WrapperComponent) ? <WrapperComponent open={open} /> : WrapperComponent)}
      {opened &&
        ReactDOM.createPortal(
          <StyledModal forceTop={forceTop} ref={modalRef}>
            <StyledModalVerticalCenterHeightBalancer />
            <ModalContent {...props} close={close}>
              {isFunction(Children) ? <Children close={close} /> : Children}
            </ModalContent>
          </StyledModal>,
          root!,
        )}
    </>
  );
}

Modal._rootElement = null! as HTMLElement;

export function setModalRootElement(element: HTMLElement) {
  Modal._rootElement = element;
}

export default React.memo(Modal);
