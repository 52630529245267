import { createAction, createAsyncAction } from "typesafe-actions";
import { ResponseMetaData } from "types";

import * as ordersConstants from "./constants";
import { OrderInterface } from "./types";

export const getOrders = createAsyncAction(ordersConstants.REQUEST, ordersConstants.SUCCESS, ordersConstants.FAILED)<
  {
    state: string;
    params?: Record<string, string>;
  },
  { data: Array<OrderInterface>; meta?: ResponseMetaData },
  string
>();

export const resetOrders = createAction(ordersConstants.RESET_ORDERS)();

export const parseOrdersMeta = createAction(ordersConstants.PARSE_ORDERS_META)<{
  count: number;
  next: string | null;
  previous: string | null;
}>();
