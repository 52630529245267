import axios from "axios";
import router from "next/router";
import { all, fork, put, select, takeEvery } from "redux-saga/effects";

import { PlaceInterface } from "containers/Pages/Offerb2b/types";
import { urls } from "constants/urls";
import { apiUrls } from "helpers/apiUrls";
import { loaderManager } from "helpers/loaderManager";
import { globalEventBus } from "helpers/globalEventBus";
import { removeScheduleWeekends } from "helpers/schedule";

import { getDadata, getPlaceCategories, getPlaces } from "./selectors";
import { fetchDadata, fetchPlaceCategories, fetchPublicOffer, setLoading } from "./actions";
import { DadataResponse, Offerb2bDadataInterface, OfferResponse, PlaceCategoriesInterface } from "./types";

const DADATA_URL = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
const DADATA_TOKEN = process.env.NEXT_PUBLIC_DADATA_API_KEY;
const DADATA_FETCH_OPTIONS = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Token " + DADATA_TOKEN,
  },
};

export function* fetchDadataRequest() {
  yield takeEvery(fetchDadata.request, function* ({ payload }) {
    try {
      yield put(setLoading(true));
      const response: DadataResponse = yield axios
        .post(DADATA_URL, JSON.stringify({ query: payload }), DADATA_FETCH_OPTIONS)
        .then((d) => d.data);
        
      if (!response.suggestions[0]) return void (yield put(fetchDadata.failure()));
      const placeCategories: PlaceCategoriesInterface[] = yield select(getPlaceCategories);
      if (placeCategories.length === 0) yield put(fetchPlaceCategories.request());

      const firstSuggestionData = response.suggestions[0].data;
      const data = {
        name: firstSuggestionData.name.full,
        address: firstSuggestionData.address.value,
        inn: firstSuggestionData.inn,
        ogrn: firstSuggestionData.ogrn,
        kpp: firstSuggestionData.kpp,
        executive_position: firstSuggestionData.management?.post || "",
        executive_fullname: firstSuggestionData.management?.name || "",
        entity_type: firstSuggestionData.opf?.short || "",
      };

      yield put(fetchDadata.success(data));
    } catch (e) {
      yield put(fetchDadata.failure());
    }
  });
}

export function* fetchDadataFailed() {
  yield takeEvery(fetchDadata.failure, function* () {
    yield put(setLoading(false));
    yield globalEventBus.emit("NOTIFICATION_TRANSLATION_ERROR", { message: "offer:common.wrong_inn" });
  });
}

export function* fetchPlaceCategoriesRequest() {
  yield takeEvery(fetchPlaceCategories.request, function* () {
    const response: OfferResponse<PlaceCategoriesInterface[]> = yield loaderManager.get(
      apiUrls.getPlaceCategoriesRequest(),
    );

    if (response.success) return void (yield put(fetchPlaceCategories.success(response.data!)));
    yield put(fetchPlaceCategories.failure(response.error!.message));
  });
}

export function* fetchPlaceCategoriesFailed() {
  yield takeEvery(fetchPlaceCategories.failure, function* ({ payload }) {
    yield globalEventBus.emit("NOTIFICATION_ERROR", { message: payload });
  });
}

export function* fetchPublicOfferRequest() {
  yield takeEvery(fetchPublicOffer.request, function* ({ payload }) {
    try {
      yield put(setLoading(true));

      const stepValues = payload;
      const places: PlaceInterface[] = yield select(getPlaces);
      const dadata: Offerb2bDadataInterface = yield select(getDadata);

      const finalData: Record<string, any> = {
        places: places.map((place) => {
          const schedule = removeScheduleWeekends(place.days, place.schedule);

          return {
            address: place.address,
            name: place.name,
            coordinates: place.coordinates,
            category: stepValues.category,
            schedule,
            phones: {
              work_main: place.workMainPhone,
              owner: place.ownerPhone,
            },
            emails: {
              order_notify_emails: [place.orderNotifyEmail],
              contact_emails: [place.contactEmail],
            },
          };
        }),
        legal_entity: {
          name: dadata.name,
          common_name: stepValues.commonName,
          entity_type: dadata.entity_type,
          legal_address: dadata.address,
          actual_address: stepValues.actualAddress,
          executive_fullname: dadata.executive_fullname,
          executive_position: dadata.executive_position,
          signer_fullname: stepValues.signerFullname,
          signer_position: stepValues.signerPosition,
          signer_email: stepValues.signerEmail,
          signer_phone: stepValues.signerPhone,
          accountant_email: stepValues.accountantEmail,
          accountant_phone: stepValues.accountantPhone,
          inn: stepValues.inn,
          kpp: dadata.kpp,
          ogrn: dadata.ogrn,
          tax_system_type: stepValues.taxSystemType,
        },
        payment_info: {
          payment_account: stepValues.paymentAccount,
          bic: stepValues.bik,
        },
      };

      if (stepValues.taxSystemType === "ENVD") finalData.legal_entity["tax_system"] = stepValues.taxSystem;

      const response: OfferResponse<void> = yield loaderManager.post(apiUrls.getOfferb2bPublicOffer(), finalData);

      if (typeof response === "object" && !response?.success) {
        yield put(setLoading(false));
        yield globalEventBus.emit("NOTIFICATION_ERROR", { message: response.error!.message });
        return;
      }

      router.push(urls.offerb2bFinish.url);
    } catch (e) {
      yield put(setLoading(false));
      yield put(fetchPublicOffer.failure());
    }
  });
}

export default function* offerSaga() {
  yield all([
    fork(fetchDadataRequest),
    fork(fetchDadataFailed),
    fork(fetchPlaceCategoriesRequest),
    fork(fetchPlaceCategoriesFailed),
    fork(fetchPublicOfferRequest),
  ]);
}
