import { createReducer } from "typesafe-actions";

import { getOrderDetails } from "./actions";
import { OrderDetailsState } from "./types";

const initialState: OrderDetailsState = {
  isLoading: false,
  wasLoaded: false,
  data: null!,
  metaData: null!,
  isError: false,
};

const reducer = createReducer(initialState)
  .handleAction(getOrderDetails.request, () => ({
    ...initialState,
    wasLoaded: false,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getOrderDetails.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: action.payload.data,
    metaData: action.payload.metaData,
  }))
  .handleAction(getOrderDetails.failure, (state) => ({
    ...state,
    isError: true,
  }));

export { reducer as orderDetailsReducer };
