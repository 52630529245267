import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";

import { getSchedule, resetSchedule } from "./actions";
import { overrideReducer } from "./overrides/reducer";
import { WorkSchedule } from "./types";
import { InitialState } from "../../types";

const initialState: InitialState<WorkSchedule> & { isError: boolean } = {
  isLoading: false,
  wasLoaded: false,
  data: null!,
  isError: false,
};

const reducer = createReducer(initialState)
  .handleAction(getSchedule.request, () => ({
    ...initialState,
    isLoading: true,
  }))
  .handleAction(getSchedule.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: action.payload,
  }))
  .handleAction(getSchedule.failure, (state) => ({
    ...state,
    isError: true,
  }))
  .handleAction(resetSchedule, (state) => initialState);

export const scheduleReducer = combineReducers({
  schedule: reducer,
  overrides: overrideReducer,
});
