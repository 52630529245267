import React from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { useTranslation } from "hooks/i18n";

const COOKIE_NAME = "NEXT_LOCALE";

export function useLanguageSetter() {
  const { pathname, replace, reload } = useRouter();
  const { language: currentLanguage } = useTranslation();

  return React.useCallback(
    (language: string) => {
      if (currentLanguage === language) return;
      Cookies.set(COOKIE_NAME, language, { path: "/" });
      replace(pathname, undefined, { locale: language, shallow: true }).then(reload);
    },
    [currentLanguage, pathname, reload, replace],
  );
}
