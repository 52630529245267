import { memoize } from "lodash";

import { InitialValues } from "containers/Pages/Subscription/forms/request/types";

import { loaderManager } from "./loaderManager";
import { apiUrls } from "./apiUrls";
import { removeScheduleWeekends } from "./schedule";
import { fetchDadata, OfferDadataInterface } from "./offer";

export const getSubscriptionRequestData = (values: InitialValues, dadata: OfferDadataInterface) => {
  const data: Record<string, any> = {
    acquiring: values.acquiring,
    place: {
      address: values.place.address, // str, required, адрес ресторана
      name: values.name, // str, required, название ресторана
      coordinates: {
        longitude: +values.place.longitude, // number, required, поле geo_lon из dadata api
        latitude: +values.place.latitude, // number, required, поле geo_lat из dadata api
      },
      // schedule: values.schedule, // WorkSchedule, required, график приёма заказов
    },
    legal_entity: {
      actual_address: values.legal_address,
      name: dadata.name, // str, required, название ЮЛ без ОПФ, поле name.full из dadata api
      entity_type: dadata.entity_type, // str, required, ОПФ, поле opf.short из dadata api
      legal_address: dadata.address, // str, required, address.value из dadata api
      executive_fullname: values.executive_fullname, // str, required for OOO, ФИО руководителя, management.name из dadata api
      executive_position: values.executive_position, // str, required for OOO, должность руководителя, management.post из dadata api
      signer_fullname: values.signer_fullname, // str, required, ФИО подписанта
      signer_position: values.signer_position, // str, required, должность подписанта
      signer_email: values.signer_email, // str, required
      accountant_email: values.accountant_email, // str, required
      accountant_phone: values.accountant_phone, // str, required
      inn: dadata.inn, // str, required
      kpp: dadata.kpp, // str, required for OOO, kpp из dadata api
      ogrn: dadata.ogrn, // str, required, ogrn из dadata api
      tax_system_type: values.tax_system_type, // str, required, OSN - TAX_SYSTEM_COMMON, USN - TAX_SYSTEM_SIMPLE, PSN - TAX_SYSTEM_PATENT, ENVD - TAX_SYSTEM_IMPUTED
      // tax_system: values.tax_system, // int, required for ENVD, 0 - TAX_RATE_VAT_0, 10 - TAX_RATE_VAT_10, 20 - TAX_RATE_VAT_20, default - TAX_RATE_NO_VAT
      avg_bill: values.avg_bill, // int, required, средний чек
      avg_cooking_time: values.avg_cooking_time, // int, required, минуты, среднее время приготовления
    },
    payment_info: {
      payment_account: values.payment_account, // str, required
      bic: values.bic, // str, required
    },
    cuisine: {
      id: values.cuisine, // number, required, айди кухни заведения
    },
    external_system: {
     // name: values.automatization_program, // string, required, программа автоматизации
      is_integration_required: values.is_integration_required, //boolean, required, нужна ли программа интеграции
    },
    contact: {
      fast_communications_email: values.fast_communications_email, // string, required, Email для оперативной связи
      orders_email: values.orders_email, // string, required, Email для заказов
      feedback_email: values.feedback_email, // string, required, Email для отзывов
      orders_phone: values.orders_phone, // string, required, телефон для заказок
      head_place_phone: values.head_place_phone, // string, required, телефон руководителя
      communications_phone: values.communications_phone, // string, required, телефон для связи
      communications_email: values.communications_email, // string, required, Email для коммуникаций
    },
  };

  if (values.leadId) data["leadId"] = values.leadId;
  if (values.customFee) data["customFee"] = values.customFee;
  if (values.tax_system_type === "OSN") data.legal_entity["tax_system"] = values.tax_system;
  if (values.automatization_program !== "none") data.external_system["name"] = values.automatization_program;

  data.place.schedule = removeScheduleWeekends(values.days, values.schedule);

  return data;
};

export const memoizedMakeDadataRequest = memoize(async (inn: string) => await fetchDadata(inn));

export async function makeSubscriptionOfferRequest(body: Record<string, any>) {
  return await loaderManager.post(apiUrls.getSubscriptionOfferRequest(), body);
}

export async function makeCuisineListRequest() {
  return await loaderManager.get(apiUrls.getCuisineListRequest());
}
