import { createSelector } from "reselect";
import { RootState } from "store";
import { namespace } from "store/auth/constants";

export const tokenExist = createSelector(
  (state: RootState) => state[namespace].token,
  (token) => !!token,
);

export const isLoading = createSelector(
  (state: RootState) => state[namespace].isLoading,
  (loading) => !!loading,
);

export const isError = createSelector(
  (state: RootState) => state[namespace].error,
  (error) => !!error,
);
