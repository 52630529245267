import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";

import { getServices } from "./actions";
import { StopListServicesResponse } from "./types";

export function* servicesRequest() {
  yield takeEvery(getServices.request, function* ({ payload }) {
    try {
      const answer: StopListServicesResponse = yield loaderManager.get(apiUrls.stopListServices(), { ...payload });
      if (answer.success) {
        yield put(
          getServices.success({
            data: answer.data,
            meta: answer.meta!,
          }),
        );
      } else {
        yield put(getServices.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getServices.failure(e as string));
    }
  });
}

export function* servicesFailed() {
  yield takeEvery(getServices.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* addonsSaga() {
  yield all([fork(servicesRequest), fork(servicesFailed)]);
}
