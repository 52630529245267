import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";

import { getOverrides } from "./actions";
import { ResponseFailType, ResponseSuccessType } from "../../../types";
import { GetWorkScheduleOverrideResponse } from "./types";

export function convertApiOverrideIntervalToDateInterface(workInterval: [string, string]) {
  return { start: workInterval[0], end: workInterval[1] };
}

export function* overridesRequest() {
  yield takeEvery(getOverrides.request, function* ({ payload }) {
    try {
      const answer: ResponseFailType & ResponseSuccessType<GetWorkScheduleOverrideResponse[]> = yield loaderManager.get(
        apiUrls.getScheduleOverride(payload),
      );

      if (answer.success) {
        yield put(
          getOverrides.success(
            answer.data.map(({ interval, period, ...override }) => ({
              ...override,
              interval: convertApiOverrideIntervalToDateInterface(interval),
              period: convertApiOverrideIntervalToDateInterface(period),
            })),
          ),
        );
      } else {
        yield put(getOverrides.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getOverrides.failure(e as string));
    }
  });
}

export function* overridesFailed() {
  yield takeEvery(getOverrides.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* overridesSaga() {
  yield all([fork(overridesRequest), fork(overridesFailed)]);
}
