import { createReducer } from "typesafe-actions";

import { checkPlaceCoordinates, setPlace, setTokenData } from "./actions";
import { OfferPlace, OfferTokenDecodedData } from "./types";

const initialState: {
  isLoading: boolean;
  wasLoaded: boolean;
  isError: boolean;
  place: OfferPlace;
  tokenData: OfferTokenDecodedData;
} = {
  isLoading: false,
  wasLoaded: false,
  isError: false,
  place: null!,
  tokenData: null!,
};

const reducer = createReducer(initialState)
  .handleAction(checkPlaceCoordinates.request, (state) => ({
    ...state,
    isLoading: true,
    wasLoaded: false,
  }))
  .handleAction(checkPlaceCoordinates.success, (state) => {
    return {
      ...state,
      isLoading: false,
      wasLoaded: true,
    };
  })
  .handleAction(checkPlaceCoordinates.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
  }))
  .handleAction(setPlace, (state, { payload }) => ({
    ...state,
    place: payload,
  }))
  .handleAction(setTokenData, (state, { payload }) => ({
    ...state,
    tokenData: payload,
  }));

export { reducer as offerb2cReducer };
