import axios from "axios";
import { Coordinates } from "@bb/components";
import { InitialValues } from "containers/Pages/Offer/forms/request/types";

import { loaderManager } from "./loaderManager";
import { apiUrls } from "./apiUrls";

export function savePlaceToSessionStorage(
  place: {
    address: string;
    valid: boolean;
  } & Coordinates,
) {
  sessionStorage.setItem("offerPlace", JSON.stringify(place));
}

export function getPlaceFromSessionStorage(): any | null {
  try {
    return JSON.parse(sessionStorage.getItem("offerPlace")!);
  } catch (error) {
    return null;
  }
}

export interface OfferDadataInterface {
  name: string;
  address: string;
  inn: string;
  ogrn: string;
  kpp: string;
  executive_position: string;
  executive_fullname: string;
  entity_type: string;
}

const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
const token = process.env.NEXT_PUBLIC_DADATA_API_KEY;

export async function fetchDadata(query: string): Promise<null | OfferDadataInterface> {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Token " + token,
    },
  };
  return await axios.post(url, JSON.stringify({ query }), options).then((d) => {
    if (!d.data.suggestions[0]) return null;

    return {
      name: d.data.suggestions[0].data.name.full,
      address: d.data.suggestions[0].data.address.value,
      inn: d.data.suggestions[0].data.inn,
      ogrn: d.data.suggestions[0].data.ogrn,
      kpp: d.data.suggestions[0].data.kpp,
      executive_position: d.data.suggestions[0].data.management?.post || "",
      executive_fullname: d.data.suggestions[0].data.management?.name || "",
      entity_type: d.data.suggestions[0].data.opf?.short || "",
    };
  });
}

export const getOfferRequestData = (values: InitialValues, dadata: OfferDadataInterface) => {
  const data: Record<string, any> = {
    acquiring: values.acquiring,
    place: {
      address: values.place.address, // str, required, адрес ресторана
      name: values.name, // str, required, назвагние ресторана
      coordinates: {
        longitude: +values.place.longitude, // number, required, поле geo_lon из dadata api
        latitude: +values.place.latitude, // number, required, поле geo_lat из dadata api
      },
    },
    legal_entity: {
      actual_address: values.legal_address,
      name: dadata.name, // str, required, название ЮЛ без ОПФ, поле name.full из dadata api
      entity_type: dadata.entity_type, // str, required, ОПФ, поле opf.short из dadata api
      legal_address: dadata.address, // str, required, address.value из dadata api
      executive_fullname: dadata.executive_fullname, // str, required for OOO, ФИО руководителя, management.name из dadata api
      executive_position: dadata.executive_position, // str, required for OOO, должность руководителя, management.post из dadata api
      signer_fullname: values.signer_fullname, // str, required, ФИО подписанта
      signer_position: values.signer_position, // str, required, должность подписанта
      signer_email: values.email, // str, required
      signer_phone: values.phone, // str, required
      accountant_email: values.accountant_email, // str, required
      accountant_phone: values.accountant_phone, // str, required
      inn: dadata.inn, // str, required
      kpp: dadata.kpp, // str, required for OOO, kpp из dadata api
      ogrn: dadata.ogrn, // str, required, ogrn из dadata api
      tax_system_type: values.tax_system_type, // str, required, OSN - TAX_SYSTEM_COMMON, USN - TAX_SYSTEM_SIMPLE, PSN - TAX_SYSTEM_PATENT, ENVD - TAX_SYSTEM_IMPUTED
      // tax_system: values.tax_system, // int, required for ENVD, 0 - TAX_RATE_VAT_0, 10 - TAX_RATE_VAT_10, 20 - TAX_RATE_VAT_20, default - TAX_RATE_NO_VAT
      avg_bill: values.avg_bill, // int, средний чек
      avg_cooking_time: values.avg_cooking_time, // int, минуты, среднее время приготовления
    },
    payment_info: {
      bank: values.bank, // str, required, название банка
      payment_account: values.payment_account, // str, required
      bic: values.bic, // str, required
      correspondent_account: values.correspondent_account, // str, required
    },
  };
  if (values.leadId) {
    data["leadId"] = values.leadId;
  }
  if (values.customFee) {
    data["customFee"] = values.customFee;
  }
  if (values.tax_system_type === "OSN") {
    data.legal_entity["tax_system"] = values.tax_system;
  }
  return data;
};

export async function makePublicOfferRequest(body: Record<string, any>) {
  return await loaderManager.post(apiUrls.getPublicOfferRequest(), body);
}
