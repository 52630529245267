import { createReducer } from "typesafe-actions";
import { EmployeeInterface } from "store/employees/types";

import { changeActivateEmployee, editEmployee, getEmployees, initEmployees, resetEmployees } from "./actions";
import { InitialState } from "../../types";

const initialState: InitialState<EmployeeInterface[]> & {
  isError: boolean;
} = {
  isLoading: false,
  wasLoaded: false,
  data: [],
  isError: false,
};

const reducer = createReducer(initialState)
  .handleAction(getEmployees.request, (state) => ({
    ...state,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getEmployees.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    data: action.payload,
    isError: false,
  }))
  .handleAction(getEmployees.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
    isError: true,
  }))
  .handleAction(initEmployees, (state, action) => ({
    ...state,
    data: action.payload,
  }))
  .handleAction(editEmployee, (state, action) => ({
    ...state,
    data: state.data.map((employee) =>
      action.payload.id !== employee.id
        ? employee
        : {
            ...employee,
            role: action.payload.role,
            place: action.payload.place,
          },
    ),
  }))
  .handleAction(changeActivateEmployee, (state, { payload }) => ({
    ...state,
    data: state.data.map((employee) => {
      if (payload.id === employee.id) {
        return {
          ...employee,
          is_active: payload.isActive,
        };
      }
      return employee;
    }),
  }))
  .handleAction(resetEmployees, () => initialState);

export { reducer as employeesReducer };
