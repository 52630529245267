import React from "react";
import { isNil } from "ramda";
import Link, { LinkProps } from "next/link";
import { isString } from "@worksolutions/utils";
import styled from "styled-components";
import { ThemeColors } from "styles/theme";
import Icon from "primitives/Icon";

import Typography, { TypographyInterface, TypographyTypes } from "./index";

type LinkTheme = "primary" | "black" | "external";

export type TypographyLinkInterface = Omit<TypographyInterface, "color" | "type" | "as"> &
  Omit<LinkProps, "as"> & {
    asLink?: LinkProps["as"];
    withDefaultStyling?: boolean;
    linkTheme?: LinkTheme;
    showExternalIcon?: boolean;
    target?: string;
    rel?: string;
    type?: Extract<TypographyTypes, "h4-regular" | "body-medium" | "body-regular" | "info-medium" | "info-regular">;
  };

const matchColorsByTheme: Record<LinkTheme, { color: ThemeColors; hoverColor: ThemeColors }> = {
  primary: { color: "black", hoverColor: "primary" },
  black: { color: "secondary", hoverColor: "black" },
  external: { color: "primary", hoverColor: "primaryHover" },
};

const StyledTypographyLink = styled.a<
  TypographyInterface & { withDefaultStyling: boolean; linkTheme: LinkTheme; ref?: React.Ref<HTMLAnchorElement> }
>`
  transition: color 0.1s;
  display: inline-flex;
  ${({ withDefaultStyling, theme, linkTheme }) =>
    withDefaultStyling && `color: ${theme.colors[matchColorsByTheme[linkTheme].color]};`}

  :hover {
    ${({ withDefaultStyling, theme, linkTheme }) =>
      withDefaultStyling && `color: ${theme.colors[matchColorsByTheme[linkTheme].hoverColor]};`}
  }
`;

const ExternalIcon = styled(Icon).attrs({ icon: "link-external-indicator" })<{ marginTop: number }>`
  margin-left: 4px;
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const matchTypographyTypeAndConfig: Record<
  Exclude<TypographyLinkInterface["type"], undefined>,
  { size: number; marginTop: number }
> = {
  "h4-regular": { size: 17, marginTop: 1 },
  "body-regular": { size: 16, marginTop: 1 },
  "body-medium": { size: 16, marginTop: 1 },
  "info-regular": { size: 16, marginTop: 1 },
  "info-medium": { size: 16, marginTop: 1 },
};

function TypographyLink(
  {
    asLink,
    href,
    passHref = true,
    withDefaultStyling = true,
    linkTheme = "primary",
    showExternalIcon,
    children,
    type = "body-regular",
    ...typographyProps
  }: TypographyLinkInterface,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const externalIconConfig = matchTypographyTypeAndConfig[type];

  const typographyComponentAs = React.useMemo(
    () =>
      React.forwardRef((props: TypographyInterface, ref: React.Ref<HTMLAnchorElement>) => (
        <StyledTypographyLink {...props} ref={ref} withDefaultStyling={withDefaultStyling} linkTheme={linkTheme} />
      )),
    [linkTheme, withDefaultStyling],
  );

  const typography = (
    <Typography ref={ref} as={typographyComponentAs as any} type={type} {...typographyProps}>
      {children}{" "}
      {needShowExternalIcon(showExternalIcon, href) && (
        <ExternalIcon
          width={externalIconConfig.size}
          height={externalIconConfig.size}
          marginTop={externalIconConfig.marginTop}
        />
      )}
    </Typography>
  );

  if (!href) return typography;

  return (
    <Link href={href} passHref={passHref} as={asLink}>
      {typography}
    </Link>
  );
}

export default React.memo(React.forwardRef(TypographyLink));

function needShowExternalIcon(showExternalIcon: boolean | undefined, href: LinkProps["href"]) {
  if (!isNil(showExternalIcon)) return showExternalIcon;
  if (isString(href)) return href.startsWith("http");
  return false;
}
