import { createAsyncAction, createAction } from "typesafe-actions";

import * as contextConstants from "./constants";
import { MerchantContextType } from "./types";

export const getMerchantContext = createAsyncAction(
  contextConstants.REQUEST,
  contextConstants.SUCCESS,
  contextConstants.FAILED,
)<void, MerchantContextType, string>();

export const initContext = createAction(contextConstants.INIT_CONTEXT)<MerchantContextType>();

export const resetContext = createAction(contextConstants.RESET_CONTEXT)();
