import { createAsyncAction } from "typesafe-actions";

import * as orderDetailsConstants from "./constants";
import { OrderDetailsInterface } from "./types";

export const getOrderDetails = createAsyncAction(
  orderDetailsConstants.REQUEST,
  orderDetailsConstants.SUCCESS,
  orderDetailsConstants.FAILED,
)<string, { data: OrderDetailsInterface; metaData: { serverTime: number } }, string>();
