import { all, fork, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-next-router";

import { urls } from "constants/urls";
import { apiUrls } from "helpers/apiUrls";
import { loaderManager } from "helpers/loaderManager";
import { globalEventBus } from "helpers/globalEventBus";
import { savePlaceToSessionStorage } from "helpers/offer";

import { Response } from "./types";
import { checkPlaceCoordinates, setPlace } from "./actions";

export function* checkCoordinatesRequest() {
  yield takeEvery(checkPlaceCoordinates.request, function* ({ payload }) {
    try {
      const answer: Response = yield loaderManager.get(apiUrls.checkCoordinates(), {
        latitude: payload.latitude,
        longitude: payload.longitude,
      });
      if (answer.success) {
        yield put(checkPlaceCoordinates.success(payload));
        if (answer.data.delivery_available) {
          yield put(setPlace({ ...payload, valid: true }));
          yield put(push(urls.offerRequest.url));
        } else {
          yield put(setPlace({ ...payload, valid: false }));
          yield put(push(urls.offerCityRequest.url));
        }
      } else {
        yield put(checkPlaceCoordinates.failure(answer.error.message));
      }
    } catch (e) {
      yield put(checkPlaceCoordinates.failure(e as string));
    }
  });
}

export function* checkCoordinatesFailed() {
  yield takeEvery(checkPlaceCoordinates.failure, function* (action) {
    yield globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export function* savePlace() {
  yield takeEvery(setPlace, function* ({ payload }) {
    yield savePlaceToSessionStorage(payload);
  });
}

export default function* offerSaga() {
  yield all([fork(checkCoordinatesRequest), fork(checkCoordinatesFailed), fork(savePlace)]);
}
