import { createAction, createAsyncAction } from "typesafe-actions";
import { Coordinates } from "@bb/components";

import * as offerConstants from "./constants";
import { OfferTokenDecodedData } from "./types";

export const setTokenData = createAction(offerConstants.SET_TOKEN_DATA)<OfferTokenDecodedData>();
export const setPlace = createAction(offerConstants.SET_PLACE)<{ address: string; valid: boolean } & Coordinates>();

export const checkPlaceCoordinates = createAsyncAction(
  offerConstants.REQUEST_COORDS,
  offerConstants.SUCCESS_COORDS,
  offerConstants.FAILED_COORDS,
)<{ address: string } & Coordinates, any, string>();
