export const internalIcons = {
  "address-dots": require("./icons/address-dots.svg"),
  alert: require("./icons/alert.svg"),
  arrow: require("./icons/arrow.svg"),
  "arrow-left": require("./icons/arrow-left.svg"),
  "arrow-up-secondary": require("./icons/arrow-up-secondary.svg"),
  arrows: require("./icons/arrows.svg"),
  attention: require("./icons/attention.svg"),
  block: require("./icons/block.svg"),
  "checkbox-tick": require("./icons/checkbox-tick.svg"),
  "close-modal": require("./icons/close-modal.svg"),
  "close-white-bg": require("./icons/close-white-bg.svg"),
  courier: require("./icons/courier.svg"),
  clock: require("./icons/clock.svg"),
  "simple-clock": require("./icons/simple-clock.svg"),
  documents: require("./icons/documents.svg"),
  download: require("./icons/download.svg"),
  edit: require("./icons/edit.svg"),
  "hide-password": require("./icons/hide-password.svg"),
  history: require("./icons/history.svg"),
  "location-infopin": require("./icons/location-infopin.svg"),
  members: require("./icons/members.svg"),
  menu: require("./icons/menu.svg"),
  "order-info-pin": require("./icons/order-info-pin.svg"),
  "order-info-time": require("./icons/order-info-time.svg"),
  orders: require("./icons/orders.svg"),
  "pin-location": require("./icons/pin-location.svg"),
  print: require("./icons/print.svg"),
  question: require("./icons/question.svg"),
  "red-flash": require("./icons/red-flash.svg"),
  reload: require("./icons/reload.svg"),
  schedule: require("./icons/schedule.svg"),
  search: require("./icons/search.svg"),
  "show-password": require("./icons/show-password.svg"),
  "sign-out": require("./icons/sign-out.svg"),
  sleep: require("./icons/sleep.svg"),
  stats: require("./icons/stats.svg"),
  stop: require("./icons/stop.svg"),
  "timeline-success": require("./icons/timeline-success.svg"),
  "timeline-warning": require("./icons/timeline-warning.svg"),
  timer: require("./icons/timer.svg"),
  trash: require("./icons/trash.svg"),
  "trash-active": require("./icons/trash-active.svg"),
  user: require("./icons/user.svg"),
  "yellow-flash": require("./icons/yellow-flash.svg"),
  "link-external-indicator": require("./icons/link-external-indicator.svg"),
  cross: require("./icons/cross.svg"),
  globe: require("./icons/globe.svg"),
};
