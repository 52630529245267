import React from "react";
import ReactSelect, { Props as ReactSelectProps, Styles as ReactSelectStyles } from "react-select";
import { DefaultTheme, useTheme } from "styled-components";
import { OptionItemInterface } from "primitives/Dropdown";
import { useTranslation } from "hooks/i18n";

interface SelectInterface extends ReactSelectProps {}

interface DefaultStylesInterface {
  theme: DefaultTheme;
}

const defaultStyles = ({ theme }: DefaultStylesInterface): ReactSelectStyles<OptionItemInterface, false> => ({
  placeholder: (provided: Record<string, any>) => ({
    ...provided,
    fontSize: theme.typography.fsz_s,
    lineHeight: "1.2",
    color: theme.colors.secondary,
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "50px",
    backgroundColor: !state.isDisabled ? theme.colors.white : theme.colors.border,
    borderRadius: theme.ui.radius_s,
    border: `1px solid ${theme.colors.border}`,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${theme.colors.border}`,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 0 2px 16px",
    height: "24px",
    flexWrap: "nowrap",
  }),
  singleValue: (provided, state) => ({
    color: state.isDisabled ? theme.colors.secondary : theme.colors.black,
    fontSize: theme.typography.fsz_s,
    lineHeight: "1.2",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "20px",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: theme.ui.radius_s,
    backgroundColor: theme.colors.white,
    marginTop: "4px",
    marginBottom: "4px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.16)",
  }),
  menuList: (provided) => ({
    ...provided,
    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
      border: "6px solid transparent",
      backgroundClip: "content-box",
      borderRadius: "20px",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "8px 16px",
    backgroundColor: state.isSelected ? theme.colors.primary : "transparent",
    "&:hover": {
      backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.primaryOpacity,
      color: theme.colors.white,
    },
  }),
});

const body = process.browser ? document.body : null;

function Select(props: SelectInterface, ref: React.Ref<ReactSelect>) {
  const { t } = useTranslation("common");

  const theme = useTheme();
  const styles = React.useMemo(
    () => ({
      ...defaultStyles({ theme }),
      ...(props.styles ? props.styles : null),
    }),
    [props.styles, theme],
  );

  const getNoOptionsMessage = React.useCallback(() => t("select.no_options"), [t]);
  const getLoadingMessage = React.useCallback(() => t("select.loading"), [t]);

  return (
    <ReactSelect
      ref={ref}
      {...props}
      styles={styles}
      components={{
        IndicatorSeparator: null,
        ...props.components,
      }}
      menuPortalTarget={body}
      noOptionsMessage={getNoOptionsMessage}
      loadingMessage={getLoadingMessage}
    />
  );
}

export default React.memo(React.forwardRef(Select));
