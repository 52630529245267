import { createReducer } from "typesafe-actions";

import { OfferDadataInterface } from "helpers/offer";

import { NumberOption } from "types/forms";

import {
  checkPlaceCoordinates,
  fetchDadata,
  fetchPlaceCuisines,
  fetchSubscriptionOffer,
  setCurrentStep,
  setPlace,
  setTokenData,
} from "./actions";

import { CurrentStepInterface, SubscriptionPlace, SubscriptionTokenDecodedData } from "./types";

const initialState: {
  isLoading: boolean;
  wasLoaded: boolean;
  isError: boolean;
  place: SubscriptionPlace;
  tokenData: SubscriptionTokenDecodedData;
  dadata: OfferDadataInterface;
  placeCuisines: NumberOption[];
  currentStep: CurrentStepInterface;
} = {
  isLoading: false,
  wasLoaded: false,
  isError: false,
  place: null!,
  tokenData: null!,
  dadata: null!,
  placeCuisines: [],
  currentStep: 1,
};

const reducer = createReducer(initialState)
  .handleAction(checkPlaceCoordinates.request, (state) => ({
    ...state,
    isLoading: true,
    wasLoaded: false,
  }))
  .handleAction(checkPlaceCoordinates.success, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
  }))
  .handleAction(checkPlaceCoordinates.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
  }))
  .handleAction(fetchPlaceCuisines.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(fetchPlaceCuisines.success, (state, { payload }) => ({
    ...state,
    placeCuisines: payload,
    isLoading: false,
  }))
  .handleAction(fetchPlaceCuisines.failure, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(fetchDadata.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(fetchDadata.success, (state, { payload }) => ({
    ...state,
    dadata: payload,
    currentStep: 2,
    isLoading: false,
  }))
  .handleAction(fetchDadata.failure, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(fetchSubscriptionOffer.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(fetchSubscriptionOffer.success, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(fetchSubscriptionOffer.failure, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(setCurrentStep, (state, { payload }) => ({
    ...state,
    currentStep: payload,
  }))
  .handleAction(setPlace, (state, { payload }) => ({
    ...state,
    place: payload,
  }))
  .handleAction(setTokenData, (state, { payload }) => ({
    ...state,
    tokenData: payload,
  }));

export { reducer as subscriptionReducer };
