import { HYDRATE } from "next-redux-wrapper";
import { AnyAction, combineReducers } from "redux";
import { routerReducer } from "connected-next-router";

import { authReducer } from "./auth/reducer";
import { namespace as authNamespace } from "store/auth/constants";
import { userReducer } from "./self/reducer";
import { namespace as userNamespace } from "store/self/constants";
import { merchantContextReducer } from "./merchantContext/reducer";
import { namespace as merchantContextNamespace } from "store/merchantContext/constants";
import { legalReducer } from "./legal/reducer";
import { namespace as legalNamespace } from "store/legal/constants";
import { merchantB2BReducer } from "./merchantB2B/reducer";
import { namespace as merchantB2BNamespace } from "store/merchantB2B/constants";
import { employeesReducer } from "./employees/reducer";
import { namespace as employeesNamespace } from "store/employees/constants";
import { documentsReducer } from "./documents/reducer";
import { namespace as documentsNamespace } from "store/documents/constants";
import { ordersReducer } from "./orders/reducer";
import { namespace as ordersNamespace } from "store/orders/constants";
import { orderDetailsReducer } from "./orderDetails/reducer";
import { namespace as orderDetailsNamespace } from "./orderDetails/constants";
import { orderCourierTrackReducer } from "./orderCourierTrack/reducer";
import { namespace as orderCourierTrackNamespace } from "./orderCourierTrack/constants";
import { uiReducer } from "./ui/reducer";
import { namespace as uiNamespace } from "store/ui/constants";
import { stopListReducer } from "store/stop-list/reducer";
import { namespace as stopListNamespace } from "store/stop-list/constants";
import { activeOrdersWidgetsReducer } from "store/active-orders-widgets/reducer";
import { namespace as activeOrdersWidgetsNamespace } from "store/active-orders-widgets/constants";
import { namespace as scheduleNamespace } from "store/schedule/constants";
import { scheduleReducer } from "store/schedule/reducer";
import { namespace as placesNamespace } from "store/places/constants";
import { merchantPlacesReducer } from "store/places/reducer";
import { namespace as statsNamespace } from "store/statistics/constants";
import { statisticsReducer } from "store/statistics/reducer";
import { namespace as offerb2cNamespace } from "store/offerb2c/constants";
import { offerb2cReducer } from "store/offerb2c/reducer";
import { namespace as offerb2bNamespace } from "store/offerb2b/constants";
import { offerb2bReducer } from "store/offerb2b/reducer";
import { namespace as subscriptionNamespace } from "store/subscription/constants";
import { subscriptionReducer } from "store/subscription/reducer";

export const combinedReducer = combineReducers({
  [authNamespace]: authReducer,
  [userNamespace]: userReducer,
  [merchantContextNamespace]: merchantContextReducer,
  [legalNamespace]: legalReducer,
  [merchantB2BNamespace]: merchantB2BReducer,
  [employeesNamespace]: employeesReducer,
  [documentsNamespace]: documentsReducer,
  [ordersNamespace]: ordersReducer,
  [orderDetailsNamespace]: orderDetailsReducer,
  [orderCourierTrackNamespace]: orderCourierTrackReducer,
  [uiNamespace]: uiReducer,
  [stopListNamespace]: stopListReducer,
  [activeOrdersWidgetsNamespace]: activeOrdersWidgetsReducer,
  [scheduleNamespace]: scheduleReducer,
  [placesNamespace]: merchantPlacesReducer,
  [statsNamespace]: statisticsReducer,
  [offerb2cNamespace]: offerb2cReducer,
  [offerb2bNamespace]: offerb2bReducer,
  [subscriptionNamespace]: subscriptionReducer,
  router: routerReducer,
});

export const reducer = (state: any, action: AnyAction) => {
  if (action.type === HYDRATE) return { ...state, ...action.payload };
  return combinedReducer(state, action);
};
