import React from "react";
import { clamp } from "ramda";
import { useEffectSkipFirst, useMeasure, useSyncToRef } from "@worksolutions/react-utils";

function useWindowScrollDelta(maxDelta: number, callback: (delta: number) => void) {
  React.useEffect(() => {
    if (maxDelta === 0) return;
    let lastScrollPosition = 0;
    let resultScrollDelta = 0;

    const listener = () => {
      const scrollDelta = window.scrollY - lastScrollPosition;
      lastScrollPosition = window.scrollY;
      const newResultScrollDelta = clamp(0, maxDelta, resultScrollDelta + scrollDelta);
      if (newResultScrollDelta === resultScrollDelta) return;
      resultScrollDelta = newResultScrollDelta;
      callback(resultScrollDelta);
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, [callback, maxDelta]);
}

export function useMenuSticky() {
  const [maxDelta, setMaxDelta] = React.useState(0);
  const [initMeasureElement, clientRect, , menuElement] = useMeasure();
  const [initMeasureDocument, documentRect] = useMeasure();
  React.useEffect(() => initMeasureDocument(document.documentElement), [initMeasureDocument]);

  useWindowScrollDelta(
    maxDelta,
    React.useCallback((delta) => (menuElement!.scrollTop = delta), [menuElement]),
  );

  const updateMaxDelta = React.useCallback(() => {
    if (!menuElement) return;
    setMaxDelta(Math.max(0, menuElement.scrollHeight - document.documentElement.clientHeight));
  }, [menuElement]);
  const updateMaxDeltaRef = useSyncToRef(updateMaxDelta);

  useEffectSkipFirst(updateMaxDelta, [updateMaxDelta, clientRect]);
  useEffectSkipFirst(() => updateMaxDeltaRef.current(), [documentRect]);

  return React.useCallback(
    (menuElement: HTMLDivElement | null) => {
      if (!menuElement) return;
      initMeasureElement(menuElement);
      updateMaxDelta();
    },
    [initMeasureElement, updateMaxDelta],
  );
}
