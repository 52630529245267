import { createReducer } from "typesafe-actions";
import { getOrders, resetOrders } from "./actions";
import { OrderInterface } from "./types";
import { InitialState, ResponseMetaData } from "../../types";

const initialState: InitialState<OrderInterface[]> & {
  meta: ResponseMetaData;
  isError: boolean;
} = {
  isLoading: false,
  wasLoaded: false,
  data: [],
  meta: {
    count: 0,
    next: "",
    previous: "",
  },
  isError: false,
};

const reducer = createReducer(initialState)
  .handleAction(getOrders.request, (state) => ({
    ...state,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getOrders.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: [...state.data, ...action.payload.data],
    meta: {
      ...state.meta,
      ...action.payload?.meta,
    },
  }))
  .handleAction(getOrders.failure, (state, action) => ({
    ...state,
    isError: true,
  }))
  .handleAction(resetOrders, (state) => initialState);

export { reducer as ordersReducer };
