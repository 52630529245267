export const commonPermissions = {
  activeOrders: ["manage:orders.active"],
  b2b: ["create:orders.b2b"],
  b2bPromo: ["!create:orders.b2b"],
  statistics: ["view:analytics"],
  history: ["view:orders.history", "view:orders.history.today"],
  stopList: ["manage:services.stop_list"],
  menu: ["manage:services.menus"],
  schedule: ["manage:open_hours.schedule"],
  employees: ["manage:users"],
  documents: ["view:documents.subscription"],
  legal: ["view:legal"],
  questions: ["merchant_faq_url <> null"],
};

export type CommonPermissionKeys = keyof typeof commonPermissions;

export const historyViewPermissions = {
  today: "view:orders.history.today",
  all: "view:orders.history",
};

export const statisticsViewPermissions = {
  today: "view:analytics.today",
  all: "view:analytics",
};
