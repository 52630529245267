import React from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import styled from "styled-components";
import { ValueType } from "react-select/src/types";
import Select from "primitives/Selects/Select";
import Typography from "primitives/Typography";
import { LargeInputHeader } from "primitives/TextField/internal/header";
import { OptionItemInterface } from "primitives/Dropdown";

import DropdownIndicator from "./DropdownIndicator";

// TODO: исправить наследование так как ReactSelectProps расширяет Record<string, any>, что не есть гуд
export interface SimpleSelectInterface extends Omit<ReactSelectProps, "onChange"> {
  label?: string;
  width?: string;
  onChange: (value: OptionItemInterface) => void;
}

export interface SimpleSelectOptionInterface<ValueType> {
  label: string;
  value: ValueType;
}

const Wrapper = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width ?? "auto"};
`;

const HeaderWrapper = styled.div<{ isError: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-end;
  ${({ isError }) => isError && "margin-bottom: 14px"};
  opacity: ${({ disabled }) => (disabled ? "0.3" : "1")};
`;

const ErrorMessage = styled(Typography).attrs({ type: "caption-regular", color: "warning" })`
  position: absolute;
  bottom: -6px;
`;

function SimpleSelect(
  { label, required, width, onChange, ...rest }: SimpleSelectInterface,
  ref: React.Ref<ReactSelect>,
) {
  const handleChange = React.useCallback(
    (value: ValueType<OptionItemInterface, false>) => {
      onChange(value!);
    },
    [onChange],
  );

  return (
    <Wrapper width={width}>
      {(label || rest.isError) && (
        <HeaderWrapper isError={rest.isError} disabled={rest.isDisabled}>
          {label && <LargeInputHeader required={required}>{label}</LargeInputHeader>}
          {rest.isError && <ErrorMessage>{rest.error}</ErrorMessage>}
        </HeaderWrapper>
      )}
      <Select
        ref={ref}
        isSearchable={false}
        {...rest}
        onChange={handleChange}
        components={{
          DropdownIndicator,
          ...rest.components,
        }}
      />
    </Wrapper>
  );
}

export default React.memo(React.forwardRef(SimpleSelect));
