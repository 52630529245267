import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import { formatISO, getUnixTime } from "date-fns";
import Cookies from "js-cookie";
import { push } from "connected-next-router";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { COOKIE_AUTH_NAME, COOKIE_MOBILE_APP_TOKEN_NAME, COOKIE_MOBILE_AUTH_TOKEN_NAME } from "constants/common";
import { defaultRedirect, urls } from "constants/urls";
import {
  initAuthTokens,
  loginAsync,
  logout,
  refreshTokensFailed,
  refreshTokensSuccess,
  resetAuth,
  restoreTokens,
} from "store/auth/actions";
import { unSubscribe } from "store/firebase/actions";
import { setWebViewMode } from "store/ui/actions";
import { RootState } from "store";
import { namespace } from "store/auth/constants";
import { LoginResponseType, LogoutSuccessType, SuccessLoginPayloadType } from "store/auth/types";
import { resetUser } from "store/self/actions";
import { resetPlaces } from "store/places/actions";

export function* loginRequest() {
  yield takeEvery(loginAsync.request, function* (action) {
    try {
      const answer: LoginResponseType = yield loaderManager.post(apiUrls.authMerchantUrl, action.payload);
      if (answer.success) {
        yield put(loginAsync.success(answer.data));
      } else {
        yield put(loginAsync.failure(answer.error));
      }
    } catch (e) {
      yield put(loginAsync.failure(e as Error & { code: number }));
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(loginAsync.success, function* (action) {
    Cookies.set(
      COOKIE_AUTH_NAME,
      JSON.stringify({
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
        token_expire: action.payload.token_expire,
      }),
      {
        expires: 365,
      },
    );
    yield put(initAuthTokens(action.payload));
    globalEventBus.emit("NOTIFICATION_TRANSLATION_SUCCESS", {
      message: "auth:success_notification",
      templates: { name: action.payload?.user.name },
    });
    const identity = {
      authentication_timestamp: getUnixTime(new Date()),
      authentication_date: formatISO(new Date()),
    };
    loaderManager.initAuthData(action.payload);
    window.amplitude.getInstance().setUserProperties(identity);
    yield put(push(defaultRedirect.url));
  });
}

export function* loginFailed() {
  yield takeEvery(loginAsync.failure, function* (action) {
    if (action.payload.code === 1) {
      window.logEvent("LogIn_Incorrect_Credentials");
    } else {
      window.logEvent("LogIn_Failed_To_Login", {
        error: action.payload.message,
        errorCode: action.payload.code,
      });
    }
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload.message });
  });
}

export function* refreshFailed() {
  yield takeEvery(refreshTokensFailed, function* () {
    yield put(logout());
  });
}

export function* refreshSuccess() {
  yield takeEvery(refreshTokensSuccess, function* (action) {
    try {
      Cookies.set(
        COOKIE_AUTH_NAME,
        JSON.stringify({
          token: action.payload.token,
          refresh_token: action.payload.refresh_token,
          token_expire: action.payload.token_expire,
        }),
        {
          expires: 365,
        },
      );
      yield put(initAuthTokens(action.payload));
      loaderManager.initAuthData(action.payload);
    } catch (e) {}
  });
}

export function* restoreTokensSaga() {
  yield takeEvery(restoreTokens, function* () {
    try {
      const state: RootState = yield select();
      if (!state[namespace]?.token) {
        let authState: SuccessLoginPayloadType = Cookies.get(COOKIE_AUTH_NAME)
          ? JSON.parse(Cookies.get(COOKIE_AUTH_NAME)!)
          : null;

        if (!authState) {
          if (Cookies.get(COOKIE_MOBILE_AUTH_TOKEN_NAME)) {
            authState = {
              token: Cookies.get(COOKIE_MOBILE_AUTH_TOKEN_NAME)!,
              token_expire: 0,
              refresh_token: "",
              user: null!,
            };

            loaderManager.setIsMobileApp();

            loaderManager.setAppToken(Cookies.get(COOKIE_MOBILE_APP_TOKEN_NAME)!);

            yield put(setWebViewMode());
          } else {
            throw new Error();
          }
        }

        yield put(initAuthTokens(authState));
        loaderManager.initAuthData(authState);
      }
    } catch (e) {
      yield put(logout());
    }
  });
}

export function* logoutSaga() {
  yield takeEvery(logout, function* () {
    try {
      const answer: LogoutSuccessType = yield loaderManager.post(apiUrls.logout());

      if (answer.success) {
        Cookies.remove(COOKIE_AUTH_NAME);
        yield put(push(urls.login.url));
        loaderManager.resetAuthData();
        yield put(resetAuth());
        yield put(resetUser());
        yield put(resetPlaces());
        yield put(unSubscribe());
      } else {
        globalEventBus.emit("NOTIFICATION_ERROR", { message: answer.error.message });
      }
    } catch (e) {
      globalEventBus.emit("NOTIFICATION_ERROR", { message: (e as Error).message });
    }
  });
}

export default function* authSaga() {
  yield all([
    fork(loginRequest),
    fork(loginFailed),
    fork(loginSuccess),
    fork(restoreTokensSaga),
    fork(refreshFailed),
    fork(logoutSaga),
    fork(refreshSuccess),
  ]);
}
