import { createReducer } from "typesafe-actions";
import { DeliveryZone, MerchantB2BInterface } from "store/merchantB2B/types";
import { InitialState } from "types";

import { getB2BAsync, getDeliveryZones, resetB2B } from "./actions";

const initialState: InitialState<MerchantB2BInterface[]> & {
  page: number;
  loadMoreLoading: boolean;
  hasNextPage: boolean;
  deliveryZones: DeliveryZone[];
} = {
  isLoading: false,
  wasLoaded: false,
  data: [],
  page: 1,
  loadMoreLoading: false,
  hasNextPage: false,
  deliveryZones: [],
};

const reducer = createReducer(initialState)
  .handleAction(getB2BAsync.request, (state, { payload }) => ({
    ...state,
    page: payload.page,
    loadMoreLoading: payload.page !== 1,
    isLoading: payload.page === 1,
  }))
  .handleAction(getB2BAsync.success, (state, { payload }) => ({
    ...state,
    data: payload.page === 1 ? payload.merchantB2B : [...state.data, ...payload.merchantB2B],
    hasNextPage: payload.hasNextPage,
    isLoading: false,
    loadMoreLoading: false,
    wasLoaded: true,
  }))
  .handleAction(getB2BAsync.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
  }))
  .handleAction(getDeliveryZones.success, (state, { payload }) => ({
    ...state,
    deliveryZones: payload.deliveryZones,
  }))
  .handleAction(resetB2B, () => initialState);

export { reducer as merchantB2BReducer };
