import { createReducer } from "typesafe-actions";

import { getOverrides, resetOverrides } from "./actions";
import { InitialState } from "../../../types";
import { WorkScheduleOverride } from "./types";

const initialState: InitialState<WorkScheduleOverride[]> & {
  isError: boolean;
} = {
  isLoading: false,
  wasLoaded: false,
  data: null!,
  isError: false,
};

const reducer = createReducer(initialState)
  .handleAction(getOverrides.request, () => ({
    ...initialState,
    isLoading: true,
  }))
  .handleAction(getOverrides.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: action.payload,
  }))
  .handleAction(getOverrides.failure, (state) => ({
    ...state,
    isError: true,
  }))
  .handleAction(resetOverrides, () => initialState);

export { reducer as overrideReducer };
