import React from "react";
import styled, { keyframes } from "styled-components";

interface SpinnerInterface {
  color?: string;
  size?: string;
}

const spinnerKeyframes = keyframes`
    from {opacity: 1;}
    to {opacity: 0.25;}
`;

const SpinnerWrapper = styled.div<SpinnerInterface>`
  position: relative;
  width: ${({ size }) => size || "24px"};
  height: ${({ size }) => size || "24px"};
  display: inline-block;
  border-radius: 10px;

  div {
    width: 6%;
    height: 16%;
    position: absolute;
    left: 49%;
    top: 43%;
    background-color: ${({ color }) => color || "#979797"};
    opacity: 0;
    -webkit-border-radius: 50px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    -webkit-animation: ${spinnerKeyframes} 1s linear infinite;
  }

  div.bar1 {
    -webkit-transform: rotate(0deg) translate(0, -130%);
    -webkit-animation-delay: 0s;
  }

  div.bar2 {
    -webkit-transform: rotate(30deg) translate(0, -130%);
    -webkit-animation-delay: -0.9167s;
  }

  div.bar3 {
    -webkit-transform: rotate(60deg) translate(0, -130%);
    -webkit-animation-delay: -0.833s;
  }
  div.bar4 {
    -webkit-transform: rotate(90deg) translate(0, -130%);
    -webkit-animation-delay: -0.7497s;
  }
  div.bar5 {
    -webkit-transform: rotate(120deg) translate(0, -130%);
    -webkit-animation-delay: -0.667s;
  }
  div.bar6 {
    -webkit-transform: rotate(150deg) translate(0, -130%);
    -webkit-animation-delay: -0.5837s;
  }
  div.bar7 {
    -webkit-transform: rotate(180deg) translate(0, -130%);
    -webkit-animation-delay: -0.5s;
  }
  div.bar8 {
    -webkit-transform: rotate(210deg) translate(0, -130%);
    -webkit-animation-delay: -0.4167s;
  }
  div.bar9 {
    -webkit-transform: rotate(240deg) translate(0, -130%);
    -webkit-animation-delay: -0.333s;
  }
  div.bar10 {
    -webkit-transform: rotate(270deg) translate(0, -130%);
    -webkit-animation-delay: -0.2497s;
  }
  div.bar11 {
    -webkit-transform: rotate(300deg) translate(0, -130%);
    -webkit-animation-delay: -0.167s;
  }
  div.bar12 {
    -webkit-transform: rotate(330deg) translate(0, -130%);
    -webkit-animation-delay: -0.0833s;
  }
`;

function Spinner(props: SpinnerInterface) {
  return (
    <SpinnerWrapper {...props} className="spinner">
      <div className="bar1" />
      <div className="bar2" />
      <div className="bar3" />
      <div className="bar4" />
      <div className="bar5" />
      <div className="bar6" />
      <div className="bar7" />
      <div className="bar8" />
      <div className="bar9" />
      <div className="bar10" />
      <div className="bar11" />
      <div className="bar12" />
    </SpinnerWrapper>
  );
}

export default React.memo(Spinner);
