import { createGlobalStyle } from "styled-components";

export const GlobalCustomStyles = createGlobalStyle`
  *, *::before, *::after {
    font-family: Roboto;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    line-height: 1;
  }

  body {
    background-color: #F9F9F9;

    @media print {
      background-color: #FFFFFF;
    }
  }

  h1, h2, h3, h4 {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @page {
    margin: 10mm 10mm;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
`;
