import { createAction, createAsyncAction } from "typesafe-actions";
import { Coordinates } from "@bb/components";

import { NumberOption } from "types/forms";
import { OfferDadataInterface } from "helpers/offer";

import * as subscriptionConstants from "./constants";

import { CurrentStepInterface, FetchSubscriptionRequestInterface, SubscriptionTokenDecodedData } from "./types";

export const setCurrentStep = createAction(subscriptionConstants.SET_CURRENT_STEP)<CurrentStepInterface>();
export const setTokenData = createAction(subscriptionConstants.SET_TOKEN_DATA)<SubscriptionTokenDecodedData>();
export const setPlace = createAction(subscriptionConstants.SET_PLACE)<
  { address: string; valid: boolean } & Coordinates
>();

export const fetchDadata = createAsyncAction(
  subscriptionConstants.REQUEST_DADATA,
  subscriptionConstants.SUCCESS_DADATA,
  subscriptionConstants.FAILED_DADATA,
)<string, OfferDadataInterface, void>();

export const checkPlaceCoordinates = createAsyncAction(
  subscriptionConstants.REQUEST_COORDS,
  subscriptionConstants.SUCCESS_COORDS,
  subscriptionConstants.FAILED_COORDS,
)<{ address: string } & Coordinates, any, string>();

export const fetchPlaceCuisines = createAsyncAction(
  subscriptionConstants.REQUEST_PLACE_CUISINES,
  subscriptionConstants.SUCCESS_PLACE_CUISINES,
  subscriptionConstants.FAILED_PLACE_CUISINES,
)<void, NumberOption[], string>();

export const fetchSubscriptionOffer = createAsyncAction(
  subscriptionConstants.REQUEST_SUBSCRIPTION_OFFER,
  subscriptionConstants.SUCCESS_SUBSCRIPTION_OFFER,
  subscriptionConstants.FAILED_SUBSCRIPTION_OFFER,
)<FetchSubscriptionRequestInterface, void, string>();
