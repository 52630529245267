import { UserInterface } from "store/self/types";
import { checkUserPermission } from "store/self/hooks";

import { MenuItem } from "./menuContent";

export function generateMenuByPermissions(self: UserInterface, menu: MenuItem[]) {
  return menu.filter(({ icon, href, hide, commonPermissionKey }) => {
    return !hide && checkUserPermission(self, commonPermissionKey);
  });
}
