import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import styled, { createGlobalStyle } from "styled-components";

export const StyledOverlayScrollbarsComponent = styled(OverlayScrollbarsComponent)`
  overscroll-behavior: none;

  &.os-host {
    overflow: hidden !important;
  }

  &.fit-content-width .os-content {
    width: fit-content !important;
  }

  &.height .os-content {
    height: fit-content !important;
  }
`;

const scrollbarPrefix = `${StyledOverlayScrollbarsComponent}.os-theme-dark > .os-scrollbar`;

export const ScrollGlobalStyles = createGlobalStyle`
  ${scrollbarPrefix} .os-scrollbar-track .os-scrollbar-handle {
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.disabled_text};
  }

  ${scrollbarPrefix} .os-scrollbar-track .os-scrollbar-handle:hover {
    background-color: ${({ theme }) => theme.colors.disabled_bg};
  }

  ${scrollbarPrefix}.os-scrollbar-vertical {
    width: 11px;
    padding-right: 4px;
    padding-left: 4px;
  }
`;
