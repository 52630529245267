import { EventEmitter as NodeEventEmitter } from "events";
import { ToastOptions } from "react-toastify";
import { EventEmitter } from "@worksolutions/utils";
import { TranslationQuery } from "next-translate";

export interface NotificationTranslateEventPayload extends NotificationEventPayload {
  templates?: TranslationQuery;
}

export interface NotificationEventPayload {
  message: string;
  options?: ToastOptions;
}

export interface NotificationPushEventPayload {
  message: { title?: string; body: string };
  options?: ToastOptions;
}

export const globalEventBus = new EventEmitter<{
  NOTIFICATION_TRANSLATION_SUCCESS: NotificationTranslateEventPayload;
  NOTIFICATION_TRANSLATION_ERROR: NotificationTranslateEventPayload;
  NOTIFICATION_PUSH: NotificationPushEventPayload;
  NOTIFICATION_SUCCESS: NotificationEventPayload;
  NOTIFICATION_ERROR: NotificationEventPayload;
  NOTIFICATION_WARNING: NotificationEventPayload;
}>(NodeEventEmitter);
