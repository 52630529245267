import React from "react";
import { ToastContainer } from "react-toastify";
import { useEventEmitter } from "@worksolutions/react-utils";
import { globalEventBus } from "helpers/globalEventBus";
import { useTranslation } from "hooks/i18n";

import { showError, showPush, showSuccess, showWarn } from "./toasts";

function NotificationsContainer() {
  const { t } = useTranslation();

  useEventEmitter(
    globalEventBus,
    "NOTIFICATION_TRANSLATION_SUCCESS",
    React.useCallback(({ options, templates, message }) => showSuccess(t(message, templates), options), [t]),
  );

  useEventEmitter(
    globalEventBus,
    "NOTIFICATION_TRANSLATION_ERROR",
    React.useCallback(({ options, templates, message }) => showError(t(message, templates), options), [t]),
  );

  useEventEmitter(
    globalEventBus,
    "NOTIFICATION_SUCCESS",
    React.useCallback(({ options, message }) => showSuccess(message, options), []),
  );

  useEventEmitter(
    globalEventBus,
    "NOTIFICATION_ERROR",
    React.useCallback(({ options, message }) => showError(message, options), []),
  );

  useEventEmitter(
    globalEventBus,
    "NOTIFICATION_PUSH",
    React.useCallback(({ options, message }) => showPush(message, options), []),
  );

  useEventEmitter(
    globalEventBus,
    "NOTIFICATION_WARNING",
    React.useCallback(({ options, message }) => showWarn(message, options), []),
  );

  return <ToastContainer />;
}

export default React.memo(NotificationsContainer);
