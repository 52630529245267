import { useCallback } from "react";
import { useAmplitude } from "react-amplitude-hooks";
import { useLocalStorage } from "react-use";
import { useMemoizeCallback, useSyncToRef } from "@worksolutions/react-utils";

export function emitAmplitudeEvent(
  logEvent: (eventName: string, data?: any) => void,
  { eventName, data }: { eventName: string; data?: any },
  logIsEnabled = true,
) {
  if (!logIsEnabled) return;
  logEvent(eventName, data);
}

const isProduction = process.env.NODE_ENV === "production";

export function useLogAmplitudeEventsIsEnabled() {
  const [logAmplitudeEvents = isProduction] = useLocalStorage<boolean>("log-amplitude-events");
  return logAmplitudeEvents;
}

function useAmplitudeLogEvent() {
  const { logEvent } = useAmplitude();
  const logEventRef = useSyncToRef(logEvent);

  const logIsEnabled = useLogAmplitudeEventsIsEnabled();

  return useCallback(
    (eventName: string, data?: any) => {
      emitAmplitudeEvent(logEventRef.current, { eventName, data }, logIsEnabled);
    },
    [logEventRef, logIsEnabled],
  );
}

export function useLogEvent() {
  const logEvent = useAmplitudeLogEvent();
  const logEventFabric = useMemoizeCallback(
    null,
    useCallback((eventName: string, data?: any) => () => logEvent(eventName, data), [logEvent]),
  );

  return [logEvent, logEventFabric] as const;
}
