import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { OptionItemInterface } from "primitives/Dropdown";

import { isWebViewMode as isWebViewModeSelector, getPlace } from "./selectors";
import { setPlace } from "./actions";

export function useUIState() {
  const dispatch = useDispatch();
  const isWebViewMode = useSelector(isWebViewModeSelector);
  const activePlace = useSelector(getPlace);

  const setActivePlace = React.useCallback((place: OptionItemInterface) => dispatch(setPlace(place)), [dispatch]);

  return {
    isWebViewMode,
    activePlace,
    setActivePlace,
  };
}
