import React from "react";
import styled from "styled-components";
import Icon from "primitives/Icon";
import Typography from "primitives/Typography";
import { useTranslation } from "hooks/i18n";

export interface LoadingErrorInterface {
  onReload: () => void;
}

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled(Typography).attrs({ type: "info-regular", align: "center" })`
  margin: 0;
`;

const ActionButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: flex-end;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

function LoadingError({ onReload }: LoadingErrorInterface) {
  const { t } = useTranslation("common");
  return (
    <ErrorWrapper>
      <Message>{t("errors.loading.message")}</Message>
      <ActionButton onClick={onReload}>
        <Icon icon="reload" color="transparent" /> {t("errors.loading.try_again")}
      </ActionButton>
    </ErrorWrapper>
  );
}

export default React.memo(LoadingError);
