import React from "react";
import HandleClickOutside from "primitives/HandleClickOutside";

import { ModalInterface, ModalSize } from "./types";
import ActionButton from "./internal/ActionButton";
import { ModalContentButtonsWrapper, StyledModalContent, ModalContentTitle } from "./internal/styled";

type ModalContentInterface = Omit<ModalInterface, "children" | "opened" | "onClose" | "onOpen" | "WrapperComponent"> & {
  close: () => void;
  children: React.ReactNode;
};

function ModalContent({
  size = ModalSize.SMALL,
  title,
  close,
  children,
  primaryActionText,
  secondaryActionText,
  primaryActionVariant = "primary",
  secondaryActionVariant = "cancel",
  primaryActionLoading,
  secondaryActionLoading,
  primaryActionDisabled,
  secondaryActionDisabled,
  excludePaddings,
  closeOnBackdropClick,
  closeOnSecondaryActionClick,
  onPrimaryActionClick,
  onSecondaryActionClick,
}: ModalContentInterface) {
  const primaryAction = primaryActionText ? (
    <ActionButton
      text={primaryActionText}
      variant={primaryActionVariant}
      loading={primaryActionLoading}
      disabled={primaryActionDisabled}
      close={close}
      onClick={onPrimaryActionClick}
    />
  ) : undefined;

  const handleSecondaryActionClick = React.useCallback(
    (close: () => void) => {
      if (closeOnSecondaryActionClick) close();
      if (onSecondaryActionClick) onSecondaryActionClick(close);
    },
    [closeOnSecondaryActionClick, onSecondaryActionClick],
  );

  const secondaryAction = secondaryActionText ? (
    <ActionButton
      text={secondaryActionText}
      variant={secondaryActionVariant}
      loading={secondaryActionLoading}
      disabled={secondaryActionDisabled}
      close={close}
      onClick={handleSecondaryActionClick}
    />
  ) : undefined;

  const titleElement = title && <ModalContentTitle>{title}</ModalContentTitle>;

  return (
    <HandleClickOutside enabled={closeOnBackdropClick} onClickOutside={close}>
      <StyledModalContent size={size} excludePaddings={excludePaddings}>
        {titleElement}
        {children}
        {(primaryAction || secondaryAction) && (
          <ModalContentButtonsWrapper>
            {secondaryAction}
            {primaryAction}
          </ModalContentButtonsWrapper>
        )}
      </StyledModalContent>
    </HandleClickOutside>
  );
}

export default React.memo(ModalContent);
