import { createReducer } from "typesafe-actions";

import { setWebViewMode, setPlace } from "./actions";
import { UIStateInterface } from "./types";

const initialState: UIStateInterface = {
  isWebViewMode: false,
  activePlace: null!,
};

const reducer = createReducer(initialState)
  .handleAction(setWebViewMode, (state) => ({
    ...state,
    isWebViewMode: true,
  }))
  .handleAction(setPlace, (state, action) => ({
    ...state,
    activePlace: action.payload,
  }));

export { reducer as uiReducer };
