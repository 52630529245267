import { createGlobalStyle } from "styled-components";
import { typographyTypes } from "primitives/Typography";

export default createGlobalStyle`
  .react-datepicker {
    display: flex;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
    border: none;
    border-radius: ${({ theme }) => theme.ui.radius_s};
    ${typographyTypes["body-regular"]};

    &-wrapper {
      width: 100%;
      height: 100%;
    }

    &__input-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-popper {
      height: 1px;
    }

    &__triangle {
      display: none;
    }

    &__header {
      background-color: ${({ theme }) => theme.colors.white};
      border: none;
    }

    &__month {
      margin: 0;
      padding: 0 10px 10px;
    }

    &__day-name {
      width: 27px;
      height: 27px;
      margin: 3px;
      color: ${({ theme }) => theme.colors.fieldsHeader};
    }

    &__day {
      width: 32px;
      height: 32px;
      ${typographyTypes["body-regular"]};
      margin: 3px 0;
      outline: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &--selected {
        color: ${({ theme }) => theme.colors.black};
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.ligh_green};

        &:hover {
          background-color: ${({ theme }) => theme.colors.ligh_green};
        }
      }

      &--keyboard-selected {
        color: ${({ theme }) => theme.colors.black};
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.ligh_green};

        &:hover {
          background-color: ${({ theme }) => theme.colors.ligh_green};
        }
      }

      &--today {
        ${typographyTypes["body-medium"]};
        background-color: ${({ theme }) => theme.colors.white};

        &:hover {
          background-color: #f0f0f0;
        }
      }

      &--in-range {
        color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.ligh_green};
        margin-left: 0;
        border-radius: 0;

        &:hover {
          color: ${({ theme }) => theme.colors.black};
          background-color: ${({ theme }) => theme.colors.success};

          &:hover {
            background-color: ${({ theme }) => theme.colors.success};
          }
        }
      }

      &--in-selecting-range {
        color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.ligh_green} !important;
        margin-left: 0;
        border-radius: 0;

        &:hover {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
          background-color: ${({ theme }) => theme.colors.ligh_green};
        }
      }

      &--range-start {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &--selecting-range-start {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &--range-end {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }
`;
