import { createAction, createAsyncAction } from "typesafe-actions";
import { ResponseMetaData } from "types";

import * as documentsConstants from "./constants";
import { DocumentInterface } from "./types";

export const getDocuments = createAsyncAction(
  documentsConstants.REQUEST,
  documentsConstants.SUCCESS,
  documentsConstants.FAILED,
)<
  {
    params?: Record<string, string>;
  },
  { data: Array<DocumentInterface>; meta?: ResponseMetaData },
  string
>();

export const resetState = createAction(documentsConstants.RESET_STATE)();

export const parseDocumentsMeta = createAction(documentsConstants.PARSE_ORDERS_META)<{
  count: number;
  next: string | null;
  previous: string | null;
}>();
