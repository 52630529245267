export const namespace = "@employees";

export const REQUEST = "@employees/REQUEST";
export const FAILED = "@employees/FAILED";
export const SUCCESS = "@employees/SUCCESS";

export const EDIT = "@employees/EDIT";

export const CHANGE_ACTIVATE = "@employees/CHANGE_ACTIVATE";

export const INIT_EMPLOYEES = "@employees/INIT_EMPLOYEES";
export const RESET_EMPLOYEES = "@employees/RESET_EMPLOYEES";
