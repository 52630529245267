import { applyMiddleware, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { createWrapper, MakeStore } from "next-redux-wrapper";
import { combinedReducer, reducer } from "./reducers";
import rootSaga from "./saga";
import { createRouterMiddleware, initialRouterState } from "connected-next-router";
import { AppContext } from "next/app";
import Router from "next/router";

import { Task } from "redux-saga";

export interface SagaStore extends Store {
  sagaTask?: Task;
}

const bindMiddleware = (middleware: any[]) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore: MakeStore = (context) => {
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware();
  const { asPath } = (context as AppContext).ctx || Router.router || {};
  let initialState = {};
  if (asPath) {
    initialState = { router: initialRouterState(asPath) };
  }

  const store = createStore(reducer, initialState, bindMiddleware([sagaMiddleware, routerMiddleware]));
  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export type RootState = ReturnType<typeof combinedReducer>;

export const wrapper = createWrapper(makeStore, { debug: false });
