import React from "react";
import { AmplitudeClient } from "amplitude-js";
import { AmplitudeContext } from "react-amplitude-hooks/dist/components/AmplitudeProvider";
import { AMPLITUDE_API_KEY } from "constants/common";
import { emitAmplitudeEvent, useLogAmplitudeEventsIsEnabled } from "hooks/analytics";
import { isBrowser } from "@bb/components";

function AmplitudeWrapper({ children }: { children: JSX.Element }) {
  const [amplitudeInstance, setAmplitudeInstance] = React.useState<AmplitudeClient>(() =>
    window.amplitude.getInstance(),
  );

  React.useLayoutEffect(() => {
    window.amplitude.getInstance().init(AMPLITUDE_API_KEY, undefined, undefined, setAmplitudeInstance);
  }, []);

  const logIsEnabled = useLogAmplitudeEventsIsEnabled();

  React.useEffect(() => {
    window.logEvent = (eventName: string, data?: any) => {
      emitAmplitudeEvent(amplitudeInstance.logEvent.bind(amplitudeInstance), { eventName, data }, logIsEnabled);
    };
  }, [amplitudeInstance, logIsEnabled]);

  return (
    <AmplitudeContext.Provider value={{ amplitudeInstance, eventProperties: {} }}>{children}</AmplitudeContext.Provider>
  );
}

export default function Amplitude({ children }: { children: JSX.Element }) {
  if (!AMPLITUDE_API_KEY || !isBrowser) return children;
  return <AmplitudeWrapper>{children}</AmplitudeWrapper>;
}
