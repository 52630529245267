import React from "react";
import styled from "styled-components";
import Icon from "primitives/Icon";

interface MenuIconInterface {
  type: string;
  active?: boolean;
}

export const StyledMenuIcon = styled.div<Partial<MenuIconInterface>>`
  width: 28px;
  height: 28px;
  svg path {
    transition: stroke 0.1s ease-in;
    stroke: ${(props) => props.active && props.theme.colors.primary};
  }
  &:hover svg path {
    stroke: ${(props) => !props.active && props.theme.colors.black};
  }
`;

function MenuIcon({ type, active }: MenuIconInterface) {
  return (
    <StyledMenuIcon active={active}>
      <Icon icon={type} />
    </StyledMenuIcon>
  );
}

export default React.memo(MenuIcon);
