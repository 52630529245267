import React from "react";
import { stringOrPixels } from "utils/stringOrPixels";
import SVG from "react-inlinesvg";
import cn from "classnames";
import styled, { useTheme } from "styled-components";

import { internalIcons } from "../../list";
import { IconVariantProps } from "../types";

const StyledSVG = styled(SVG)`
  display: inline-block;
  min-width: ${({ width }) => width};
  min-height: ${({ width }) => width};

  path {
    transition: stroke 100ms;
  }
`;

function InternalSvg({
  className,
  icon: rawIcon,
  width: propWidth,
  height: propHeight,
  color,
}: IconVariantProps<keyof typeof internalIcons>) {
  const icon = React.useMemo(() => internalIcons[rawIcon].default, [rawIcon]);
  const strokeColor = useTheme().colors[color];

  return (
    <StyledSVG
      stroke={strokeColor}
      width={stringOrPixels(propWidth ?? icon.width)}
      height={stringOrPixels(propHeight ?? icon.height)}
      src={icon.src}
      className={cn(className, "icon")}
    />
  );
}

export default React.memo(InternalSvg);
