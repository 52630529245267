import { createReducer } from "typesafe-actions";

import { getPlacesAsync, resetPlaces } from "./actions";
import { MerchantPlace } from "./types";
import { InitialState } from "../../types";

const initialState: InitialState<MerchantPlace[]> = {
  isLoading: false,
  wasLoaded: false,
  data: [],
};

const reducer = createReducer(initialState)
  .handleAction(getPlacesAsync.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(getPlacesAsync.success, (state, action) => ({
    ...state,
    data: action.payload,
    isLoading: false,
    wasLoaded: true,
  }))
  .handleAction(getPlacesAsync.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
  }))
  .handleAction(resetPlaces, () => initialState);

export { reducer as legalReducer };
