import axios, { AxiosRequestConfig } from "axios";
import Router from "next/router";
import { customAxios } from "helpers/customAxios";
import { SuccessLoginPayloadType } from "store/auth/types";
import { APP_TOKEN_HEADER_NAME, APP_TOKEN_HEADER_VALUE, AUTH_HEADER_NAME } from "constants/common";

const CancelToken = axios.CancelToken;

const cancelTokenMap = new Map();

export const initOptions = (url: string): AxiosRequestConfig => {
  const source = CancelToken.source();
  cancelTokenMap.set(url, source);
  const { locale } = Router;
  const options: AxiosRequestConfig = {
    cancelToken: source.token,
    headers: {
      [APP_TOKEN_HEADER_NAME]: loaderManager.APP_TOKEN,
    },
  };

  if (locale) options.headers!["Accept-Language"] = locale;

  if (!loaderManager.authData?.token) {
    return options;
  }

  return {
    ...options,
    headers: {
      ...options.headers,
      [AUTH_HEADER_NAME]: loaderManager.authData?.token,
    },
  };
};

class LoaderManager {
  authData: SuccessLoginPayloadType = null!;
  successRefreshAuthCb: (data: SuccessLoginPayloadType) => void = null!;
  failRefreshAuthCb: () => void = null!;
  APP_TOKEN: string = null!;
  isMobileApp = false;

  constructor() {
    this.APP_TOKEN = APP_TOKEN_HEADER_VALUE!;
  }

  initAuthData(authData: SuccessLoginPayloadType) {
    this.authData = authData;
  }

  resetAuthData() {
    // TODO: check it
    // authData: null;
  }

  setCallbacks(successCb: (data: SuccessLoginPayloadType) => void, failCb: () => void) {
    this.successRefreshAuthCb = successCb;
    this.failRefreshAuthCb = failCb;
  }

  setAppToken(token: string) {
    this.APP_TOKEN = token;
  }

  setIsMobileApp() {
    this.isMobileApp = true;
  }

  get(url: string, data?: unknown) {
    const options = initOptions(url);

    return customAxios
      .get(url, { ...options, params: data })
      .then((res) => ({
        ...res.data,
        __headers: res.headers,
      }))
      .catch((err) => err.response.data);
  }

  post(url: string, data?: unknown, params?: Record<string, string>) {
    const options = initOptions(url);

    return customAxios
      .post(url, data, {
        ...options,
        headers: {
          ...options.headers,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params,
      })
      .then((res) => res.data)
      .catch((err) => err.response.data);
  }

  put(url: string, data: unknown) {
    const options = initOptions(url);

    return customAxios
      .put(url, data, options)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.data) {
          return err.response.data;
        } else {
          throw new Error(err.message);
        }
      });
  }

  delete(url: string) {
    const options = initOptions(url);

    return customAxios
      .delete(url, options)
      .then((res) => res.data)
      .catch((err) => err.response.data);
  }

  cancelRequest(url: string) {
    const source = cancelTokenMap.get(url);
    if (source) {
      source.cancel();
    }
  }
}

const loaderManager = new LoaderManager();

export { loaderManager };
