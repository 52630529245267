import { CategoryInterface } from "./types";
import { createReducer } from "typesafe-actions";
import { addCategoryServiceToStopList, getCategories, removeCategoryServiceFromStopList } from "./actions";
import { InitialState } from "../../../types";

const initialState: InitialState<CategoryInterface[]> = {
  isLoading: false,
  wasLoaded: false,
  isError: false,
  data: [],
};

const reducer = createReducer(initialState)
  .handleAction(getCategories.request, (state) => ({
    ...state,
    wasLoaded: false,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getCategories.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: action.payload,
  }))
  .handleAction(getCategories.failure, (state) => ({
    ...state,
    isLoading: false,
    wasLoaded: false,
    isError: true,
  }))
  .handleAction(removeCategoryServiceFromStopList, (state, action) => ({
    ...state,
    data: state.data.map((category) => {
      return {
        ...category,
        subcategories: category.subcategories.map((subcategory) => {
          if (subcategory.id === action.payload) {
            return {
              ...subcategory,
              services_count: {
                ...subcategory.services_count,
                in_stop_list: subcategory.services_count.in_stop_list - 1,
              },
            };
          }

          return subcategory;
        }),
      };
    }),
  }))
  .handleAction(addCategoryServiceToStopList, (state, action) => ({
    ...state,
    data: state.data.map((category) => {
      return {
        ...category,
        subcategories: category.subcategories.map((subcategory) => {
          if (subcategory.id === action.payload) {
            return {
              ...subcategory,
              services_count: {
                ...subcategory.services_count,
                in_stop_list: subcategory.services_count.in_stop_list + 1,
              },
            };
          }

          return subcategory;
        }),
      };
    }),
  }));

export { reducer as categoriesReducer };
