import { createReducer } from "typesafe-actions";
import { MerchantContextType } from "store/merchantContext/types";

import { initContext, resetContext } from "./actions";

const initialState: MerchantContextType = null!;

const reducer = createReducer(initialState)
  .handleAction(initContext, (state, action) => action.payload)
  .handleAction(resetContext, () => initialState);

export { reducer as merchantContextReducer };
