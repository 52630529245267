import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";

import { getOrderCourierTrack } from "./actions";
import { OrderCourierTrackResponse } from "./types";

export function* orderCourierTrackRequest() {
  yield takeEvery(getOrderCourierTrack.request, function* ({ payload }) {
    try {
      const answer: OrderCourierTrackResponse = yield loaderManager.get(apiUrls.orderCourierTrack(payload.orderId));
      if (answer.success) {
        yield put(getOrderCourierTrack.success(answer));
      } else {
        yield put(getOrderCourierTrack.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getOrderCourierTrack.failure(e as string));
    }
  });
}

export function* orderCourierTrackFailed() {
  yield takeEvery(getOrderCourierTrack.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* orderCourierTrackSaga() {
  yield all([fork(orderCourierTrackRequest), fork(orderCourierTrackFailed)]);
}
