import styled, { css, keyframes } from "styled-components";
import Typography, { typographyTypes } from "primitives/Typography";

const requiredInputHeaderStyles = css`
  :after {
    content: "*";
    color: ${({ theme }) => theme.colors.warning};
    position: relative;
    left: 2px;
    top: -2px;
  }
`;

export const LargeInputHeader = styled(Typography)<{
  required?: boolean;
  disabled?: boolean;
}>`
  ${({ required }) => required && requiredInputHeaderStyles};
  margin-bottom: 13px;
  color: ${({ theme }) => theme.colors.fieldsHeader};
  ${typographyTypes["info-regular"]};
  opacity: ${({ disabled }) => (disabled ? "0.3" : "1")};
`;

export const CompactInputHeader = styled(Typography)<{
  required?: boolean;
  active: boolean;
  disabled?: boolean;
}>`
  ${({ required }) => required && requiredInputHeaderStyles};
  color: ${({ theme }) => theme.colors.secondary};
  ${typographyTypes["body-regular"]};
  transition: 0.1s font-size, 0.1s line-height, 0.1s top;
  position: absolute;
  top: 0;
  ${({ active }) => active && ["top: -10px;", ...typographyTypes["info-medium"]]};
  opacity: ${({ disabled }) => (disabled ? "0.3" : "1")};
`;

const appearKeyframes = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const ErrorMessage = styled(Typography).attrs({ color: "warning", type: "caption-regular" })<{
  hasHint: boolean;
}>`
  margin-top: 4px;
  animation: ${appearKeyframes} 0.5s ease-in-out;
  ${({ hasHint }) => hasHint && "top: 100%; position: absolute;"}
`;

export const Hint = styled(Typography).attrs({ color: "fieldsHeader", type: "caption-regular" })`
  margin-top: 4px;
`;
