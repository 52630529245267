import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getCategories } from "./actions";
import { CategoriesResponse } from "./types";

export function* categoriesRequest() {
  yield takeEvery(getCategories.request, function* ({ payload }) {
    try {
      const answer: CategoriesResponse = yield loaderManager.get(apiUrls.stopListCategories(), payload);
      if (answer.success) {
        yield put(getCategories.success(answer.data));
      } else {
        yield put(getCategories.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getCategories.failure(e as string));
    }
  });
}

export function* categoriesFailed() {
  yield takeEvery(getCategories.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* addonsSaga() {
  yield all([fork(categoriesRequest), fork(categoriesFailed)]);
}
