import { createReducer } from "typesafe-actions";

import { getOrderCourierTrack } from "./actions";
import { OrderCourierTrackState } from "./types";

const initialState: OrderCourierTrackState = {
  isLoading: false,
  wasLoaded: false,
  data: null!,
};

const reducer = createReducer(initialState)
  .handleAction(getOrderCourierTrack.request, (state, { payload: { clearOrder = true } }) => ({
    ...initialState,
    data: clearOrder ? initialState.data : state.data,
    wasLoaded: false,
    isLoading: true,
    isError: false,
  }))
  .handleAction(getOrderCourierTrack.success, (state, action) => ({
    ...state,
    isLoading: false,
    wasLoaded: true,
    isError: false,
    data: action.payload.data,
  }))
  .handleAction(getOrderCourierTrack.failure, (state) => ({
    ...state,
    isError: true,
  }));

export { reducer as orderCourierTrackReducer };
