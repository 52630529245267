import { all, fork, put, takeEvery } from "redux-saga/effects";
import { apiUrls } from "helpers/apiUrls";
import { loaderManager } from "helpers/loaderManager";
import { globalEventBus } from "helpers/globalEventBus";

import { getStatisticsAsync } from "./actions";
import { Response } from "./types";
import { mapper } from "./mapper";

export function* statisticsRequest() {
  yield takeEvery(getStatisticsAsync.request, function* ({ payload }) {
    try {
      const answer: Response = yield loaderManager.get(apiUrls.getMerchantStatistics(), payload);
      if (answer.success) {
        yield put(getStatisticsAsync.success(mapper(answer.data)));
      } else {
        yield put(getStatisticsAsync.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getStatisticsAsync.failure(e as string));
    }
  });
}

export function* statisticsFailed() {
  yield takeEvery(getStatisticsAsync.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* statisticsSaga() {
  yield all([fork(statisticsRequest), fork(statisticsFailed)]);
}
