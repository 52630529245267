import React from "react";
import styled from "styled-components";
import LanguageSelector from "containers/LanguageSelector";

const LanguageWrapper = styled.div`
  margin-top: 23px;
`;

function Language() {
  return (
    <LanguageWrapper>
      <LanguageSelector />
    </LanguageWrapper>
  );
}

export default React.memo(Language);
