import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getActiveOrdersWidgets } from "./actions";
import { ActiveOrdersWidgetsResponse } from "./types";

export function* activeOrdersWidgetsRequest() {
  yield takeEvery(getActiveOrdersWidgets.request, function* (action) {
    try {
      const answer: ActiveOrdersWidgetsResponse = yield loaderManager.get(
        apiUrls.activeOrdersWidgets(action.payload.placeId),
      );
      if (answer.success) {
        yield put(
          getActiveOrdersWidgets.success({
            data: answer.data,
            metaData: {
              serverTime: parseInt(answer.__headers["x-server-time"], 10),
            },
          }),
        );
      } else {
        yield put(getActiveOrdersWidgets.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getActiveOrdersWidgets.failure(e as string));
    }
  });
}

export function* activeOrdersWidgetsFailed() {
  yield takeEvery(getActiveOrdersWidgets.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* activeOrdersWidgetsSaga() {
  yield all([fork(activeOrdersWidgetsRequest), fork(activeOrdersWidgetsFailed)]);
}
