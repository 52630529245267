import { WEEKDAYS } from "constants/weekDays";
import { WorkSchedule } from "store/schedule/types";

const INTERVAL = 30;
const LAST_INTERVAL_HOUR = 24;

const parseTime = (hours: number, minutes: number) => {
  return `${hours < 10 ? `0${hours}` : `${hours}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}:${
    minutes === 59 ? "59" : "00"
  }`;
};

export function generateIntervalList(start = "00:00:00", end = "24:00:00", interval = INTERVAL) {
  const list = [start];
  const [startHour, startMinutes] = start.split(":");
  const [endHour, endMinutes] = end.split(":");

  let numStartHour = parseInt(startHour, 10);
  let numEndHour = parseInt(endHour, 10);

  if (numStartHour > numEndHour) {
    throw new Error("start hours must be grater then end");
  }

  let numStartMins = parseInt(startMinutes, 10);
  let numEndMins = parseInt(endMinutes, 10);

  if (numStartHour === numEndHour) {
    if (numStartMins > numEndMins) {
      throw new Error("start minutes must be less then end");
    }
  }

  while (numStartHour !== numEndHour) {
    if (numStartMins + interval >= 60) {
      if (numStartHour + 1 === LAST_INTERVAL_HOUR) {
        if (numStartMins === 59) {
          break;
        }
        numStartMins = 59;
        list.push(parseTime(numStartHour, numStartMins));
        break;
      }
      numStartHour += 1;
      numStartMins = 0;
    } else {
      numStartMins += interval;
    }
    list.push(parseTime(numStartHour, numStartMins));
  }

  while (numStartHour !== numEndHour) {
    if (numStartMins + interval >= 60) {
      if (numStartHour + 1 === LAST_INTERVAL_HOUR) {
        if (numStartMins === 59) {
          break;
        }
        numStartMins = 59;
        list.push(parseTime(numStartHour, numStartMins));
        break;
      }
      numStartHour += 1;
      numStartMins = 0;
    } else {
      numStartMins += interval;
    }
    list.push(parseTime(numStartHour, numStartMins));
  }

  if (numEndHour !== 24) {
    while (numStartMins !== numEndMins) {
      numStartMins += interval;
      list.push(parseTime(numStartHour, numStartMins));
    }
  }

  return list;
}

export function isGrater(t1: string, t2: string) {
  const [t1h, t1m] = t1.split(":");
  const [t2h, t2m] = t2.split(":");

  if (parseInt(t1h, 10) > parseInt(t2h, 10)) {
    return true;
  }
  if (parseInt(t1h, 10) === parseInt(t2h, 10)) {
    return parseInt(t1m, 10) > parseInt(t2m, 10);
  }

  return false;
}

export function isEqual(t1: string, t2: string) {
  const [t1h, t1m] = t1.split(":");
  const [t2h, t2m] = t2.split(":");

  if (parseInt(t1h, 10) === parseInt(t2h, 10)) {
    return parseInt(t1m, 10) === parseInt(t2m, 10);
  }

  return false;
}

export function removeScheduleWeekends(days: typeof WEEKDAYS, schedule: WorkSchedule) {
  return schedule.map((day, i) => (days.includes(WEEKDAYS[i]) ? day : []));
}
