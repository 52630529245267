import { RootState } from "store";
import { createSelector } from "reselect";

import { namespace } from "./constants";

export const selfState = (state: RootState) => state[namespace];

export const getIsLoading = createSelector(selfState, (state) => state.isLoading);
export const getDadata = createSelector(selfState, (state) => state.dadata);
export const getCurrentStep = createSelector(selfState, (state) => state.currentStep);
export const getPlaceCategories = createSelector(selfState, (state) => state.placeCategories);
export const getPlaces = createSelector(selfState, (state) => state.places);
