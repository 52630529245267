module.exports = {
  locales: ["ru", "uz"],
  defaultLocale: "ru",
  interpolation: { prefix: "{", suffix: "}" },
  loadLocaleFrom: (lang, ns) => import(`./public/translations/${lang}/${ns}.json`).then((m) => m.default),
  pages: {
    "*": ["common", "layout", "validations", "workload"],
    "/offer": ["offer"],
    "/offerb2b": ["offer", "offerb2b", "schedule"],
    "/offerb2b/agreement": ["offerb2b_agreement"],
    "/offerb2b/finish": ["offer", "offerb2b"],
    "/offer/request": ["offer"],
    "/offer/city-request": ["offer"],
    "/offer/finish": ["offer"],
    "/offer/agreement": ["offer_agreement"],
    "/subscription": ["subscription"],
    "/subscription/request": ["subscription", "schedule"],
    "/subscription/city-request": ["subscription"],
    "/subscription/finish": ["subscription"],
    "/subscription/agreement": ["subscription_agreement"],
    "/auth/login": ["auth"],
    "/auth/restore": ["auth"],
    "/auth/password-reset": ["auth"],
    "/auth/invite": ["auth"],
    "/app/orders/active": ["common_orders", "active_orders"],
    "/app/orders/history": ["common_orders", "history", "active_orders"],
    "/app/b2b": ["offer", "b2b"],
    "/app/stop-list": ["stop_list"],
    "/app/dashboard": ["dashboard"],
    "/app/schedule": ["schedule"],
    "/app/staff": ["staff"],
    "/app/documents": ["documents"],
    "/app/legal": ["legal"],
  },
  logBuild: false,
};
