import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loaderManager } from "helpers/loaderManager";
import { apiUrls } from "helpers/apiUrls";
import { globalEventBus } from "helpers/globalEventBus";
import { getOrders, parseOrdersMeta } from "store/orders/actions";
import { OrdersResponse } from "store/orders/types";

export function* ordersRequest() {
  yield takeEvery(getOrders.request, function* ({ payload: { state, params = {} } }) {
    try {
      const answer: OrdersResponse = yield loaderManager.get(apiUrls.merchantOrderList(state), params);
      if (answer.success) {
        yield put(getOrders.success({ data: answer.data, meta: answer.meta }));
      } else {
        yield put(getOrders.failure(answer.error.message));
      }
    } catch (e) {
      yield put(getOrders.failure(e as string));
    }
  });
}

export function* ordersFailed() {
  yield takeEvery(getOrders.failure, function* (action) {
    globalEventBus.emit("NOTIFICATION_ERROR", { message: action.payload });
  });
}

export default function* ordersSaga() {
  yield all([fork(ordersRequest), fork(ordersFailed)]);
}
